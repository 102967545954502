import React from "react";
import { useSessionStorage } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";

import { useLocation } from "react-router";
import { toast } from "react-toastify";
import {
   Box,
   Button,
   IconButton,
   InputLabel,
   NativeSelect,
   TextField,
   Tooltip,
} from "@mui/material";
// import type {
//    GridColDef,
//    GridRowSelectionModel,
//    GridSortModel,
// } from "@mui/x-data-grid";
import HistoryIcon from "@mui/icons-material/History";
import { DataGrid } from "@mui/x-data-grid";

import PeopleCard from "../components/PeopleSearch/PeopleCard";
import { Search } from "@mui/icons-material";
import { useAppDispatch } from "../app/hooks.ts";
import { openDialog } from "../features/authDialog/authDialogSlice.ts";
import { baseUrl } from "../services/api";
import Spinner from "../ui/Spinner";
import axios from "axios";
import { set } from "lodash";

const columns =
   //: GridColDef[]
   [
      { field: "public_profile_id", headerName: "ID", hideable: true },
      { field: "score", headerName: "Score", hideable: true },
      {
         field: "Results",
         hideable: false,
         resizable: false,
         flex: 1,
         sortable: false,

         renderCell: (props) => <PeopleCard {...props.row} />,
      },
   ];
// interface Result {
//    public_profile_id: string;
//    score: number;
//    [key: string]: any;
// }
export default function Query() {
   const location = useLocation();

   const [lastQuery, setLastQuery] = useSessionStorage("lastQuery", "");
   const [query, setQuery] = useState(
      new URLSearchParams(location.search).get("query") || lastQuery
   );

   const [sortBy, setSortBy] = useState([]);
   const [selectedRows, setSelectedRows] = useState([]);

   const [isLoading, setIsLoading] = useState(false);
   const [offset, setOffset] = useState(0);
   const [totalResults, setTotalResults] = useState(undefined);
   //TODO: Implement concatenatedQueries
   //const [concatenatedQueries, setConcatenatedQueries] = useState("")

   const [resultsCache, setResultsCache] = useSessionStorage(
      "resultsCache",
      {}
   );
   const [results, setResults] = useState(resultsCache);

   const [sessionID, setSessionID] = useSessionStorage("sessionID", "");

   const linkedinCredentials = useSessionStorage("linkedinCredentials", {});
   console.log(linkedinCredentials[0]);

   const dispatch = useAppDispatch();

   function handleSort(value) {
      switch (value) {
         case "score_desc":
            setSortBy([{ field: "score", sort: "desc" }]);
            break;
         case "score_asc":
            setSortBy([{ field: "score", sort: "asc" }]);
            break;
         default:
            setSortBy([]);
      }
   }

   function clearUrl() {
      const url = new URL(window.location.href);
      url.searchParams.delete("query");
      window.history.replaceState({}, document.title, url);
   }

   /**
    * Handles the search functionality by making an API call and updating the results.
    */
   async function handleSearch() {
      console.log(query);
      setIsLoading(true);
      setOffset((prevState) => prevState + 10);
      try {
         // const response = await axios({
         //   method: "POST",
         //   url: `${import.meta.env.VITE_BACKEND_URL}/search_people_chat?query=${encodeURIComponent(query)}&session_id=${sessionID}&offset=${offset}`,
         //   data: linkedinCredentials[0],
         //   headers: { "Content-Type": "application/json" },
         // })
         const response = await fetch(
            `${baseUrl}/linkedin/get_linkedin_profiles?query=${encodeURIComponent(
               query
            )}&session_id=${sessionID}&offset=${offset}`,
            {
               method: "POST",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify(linkedinCredentials[0]),
            }
         );

         if (!response.body) throw new Error("No response body");
         const reader = response.body?.getReader();
         if (reader) {
            const decoder = new TextDecoder();
            let done = false;

            while (!done) {
               const { value, done: readerDone } = await reader.read();
               done = readerDone;

               if (value) {
                  const chunk = decoder.decode(value, { stream: true });
                  console.log("Chunk received:", chunk);
                  try {
                     for (const smallChunk of chunk.split("\n")) {
                        if (smallChunk !== "") {
                           const data = JSON.parse(smallChunk);
                           if (!totalResults)
                              setTotalResults(data.total_results);
                           if (
                              data.profiles.length > 0 &&
                              Object.values(results).length < 100
                           )
                              setResults((results) => ({
                                 ...results,
                                 [data.profiles[0].public_profile_id]:
                                    data.profiles[0],
                              }));
                        }
                     }
                  } catch (error) {
                     throw new Error("error parsing JSON");
                  }
               }
            }
         } else {
            const data = await response.json();
            console.log("Data received:", data);
            setResults((results) => ({
               ...results,
               [data.profiles[0].public_profile_id]: data.profiles[0],
            }));
         }
      } catch (err) {
         if (Object.keys(linkedinCredentials).length === 0) {
            dispatch(openDialog());
            toast.error("Please login to LinkedIn");
         } else if (err instanceof Error) {
            toast.error(err.message);
         } else {
            toast.error("An unknown error occurred");
         }
      }

      setIsLoading(false);
      clearUrl();
      if (query !== "") setLastQuery(query);
   }

   async function handleConnection() {
      if (selectedRows.length === 0) {
         toast.error("Please select at least one user");
         return;
      }
      console.log(linkedinCredentials[0]?.li_at_cookie);
      setIsLoading(true);
      axios
         .all(
            selectedRows.map((row) =>
               axios.post(`${baseUrl}/linkedin/connect/${row}`, {
                  li_at_cookie: linkedinCredentials[0]?.li_at_cookie,
                  message: "Hi, I would like to connect with you!",
               })
            )
         )
         .then((response) => {
            toast.success("Connection request sent successfully");
         })
         .catch((error) => {
            console.log(error);
            toast.error("An error occurred while connecting to the users");
         })
         .finally(() => {
            setIsLoading(false);
            setSelectedRows([]);
         });
      console.log("users selected", selectedRows);
   }

   useEffect(() => {
      //console.log("result cache change")
      setResultsCache(results);
   }, [results, setResultsCache]);

   useEffect(() => {
      if (sessionID === "") {
         setSessionID(
            `${Date.now().toString(36)}${Math.random()
               .toString(36)
               .substring(2)}`
         );
         return;
      }

      //console.log("Query", query, typeof query, query.length)
      //console.log("LastQuery", lastQuery, typeof lastQuery, lastQuery.length)

      if (query.length > 0 && query !== lastQuery) {
         //The second search from landing page
         setResults({});
         handleSearch();
      } else if (Object.keys(resultsCache).length === 0)
         //The first search from landing page
         handleSearch();

      //refreshing the page
      //setResults(resultsCache)
      clearUrl();
   }, [sessionID]);

   return (
      <div>
         {Object.keys(results).length === 0 ? (
            <Spinner />
         ) : (
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "8rem",
               }}
            >
               <Box
                  sx={{
                     marginTop: "1rem",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-between",
                     width: "70%",
                  }}
               >
                  <TextField
                     id="outlined-search"
                     type="search"
                     multiline
                     placeholder="Input the candidate you are looking for"
                     value={query}
                     onChange={(e) => setQuery(e.target.value)}
                     sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                           "& fieldset": {
                              borderColor: "#5fcf80",
                           },
                           "&:hover fieldset": {
                              borderColor: "#5fcf80",
                           },
                           "&.Mui-focused fieldset": {
                              borderColor: "#5fcf80",
                           },
                        },
                        "& .MuiInputLabel-root": {
                           color: "#397c4d",
                           "&.Mui-focused": {
                              color: "#397c4d", // Ensure label color stays the same when focused
                           },
                        },
                        "& .MuiInputBase-input": {
                           color: "#397c4d",
                        }, // Ensure label color stays the same when focused
                     }}
                  />
                  <Tooltip title="Search for the candidate">
                     <IconButton
                        disabled={isLoading || query === ""}
                        onClick={() => {
                           if (query === "") {
                              toast.error("Please enter a search query");
                              return;
                           }
                           setResults({});
                           setResultsCache({});
                           setOffset(0);
                           setTotalResults(undefined);
                           handleSearch();
                        }}
                     >
                        <Search sx={{ color: "#56ba73" }} />
                     </IconButton>
                  </Tooltip>

                  <Tooltip title="Reuse the last query">
                     <IconButton
                        disabled={isLoading}
                        onClick={() => setQuery(lastQuery)}
                     >
                        <HistoryIcon sx={{ color: "#56ba73" }} />
                     </IconButton>
                  </Tooltip>
               </Box>

               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-between",
                     width: { sm: "70%", md: "50%" },
                     marginTop: "1rem",
                  }}
               >
                  <Box>
                     <InputLabel
                        variant="standard"
                        htmlFor="uncontrolled-native"
                     >
                        OrderBy
                     </InputLabel>
                     <NativeSelect
                        value={
                           sortBy.length > 0
                              ? `${sortBy[0].field}_${sortBy[0].sort}`
                              : "default"
                        }
                        inputProps={{
                           name: "orderby",
                           id: "uncontrolled-native",
                        }}
                        onChange={(e) => handleSort(e.target.value)}
                     >
                        <option value="score_desc">Score Descending</option>
                        <option value="score_asc">Score Ascending</option>
                        <option value="default">Default</option>
                     </NativeSelect>
                  </Box>
                  <Button
                     sx={{
                        backgroundColor: "#5fcf80",
                        "&:hover": {
                           backgroundColor: "#56ba73",
                        },
                        borderRadius: "20px",
                     }}
                     variant="contained"
                     disabled={
                        isLoading ||
                        query !== lastQuery ||
                        100 <= Object.keys(results).length
                     }
                     onClick={handleSearch}
                  >
                     Get More Results
                  </Button>

                  <Button
                     sx={{
                        backgroundColor: "#5fcf80",
                        "&:hover": {
                           backgroundColor: "#56ba73",
                        },
                        borderRadius: "20px",
                     }}
                     variant="contained"
                     onClick={handleConnection}
                  >
                     Build connection
                  </Button>
               </Box>
               <DataGrid
                  sx={{ width: { xs: "100%", sm: "80%" }, maxWidth: "750px" }}
                  rows={Object.values(results)}
                  columns={columns}
                  getRowHeight={() => "auto"}
                  sortModel={sortBy}
                  onSortModelChange={(model) => setSortBy(model)}
                  getRowId={(result) => result.public_profile_id}
                  rowSelectionModel={selectedRows}
                  onRowSelectionModelChange={(newSelection) => {
                     setSelectedRows(newSelection);
                  }}
                  checkboxSelection
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnMenu
                  initialState={{
                     columns: {
                        columnVisibilityModel: {
                           public_profile_id: false,
                           score: false,
                        },
                     },
                     pagination: {
                        paginationModel: {
                           pageSize: 5,
                        },
                     },
                  }}
               />
            </Box>
         )}
      </div>
   );
}
