import {
   Button,
   Card,
   CardContent,
   CardMedia,
   Divider,
   Grid2,
   Stack,
   Typography,
 } from "@mui/material"
 import Box from "@mui/material/Box"
 import heroBg from "../assets/hero-new-bg.jpg";
 import Carousel from "../components/Carousel.tsx"
 import CustomizedCard from "../components/CustomizedCard.tsx"
 // import ComparationTable from "../components/Comparation.tsx"
 import HowToUse from "../components/HowToUse.tsx"
 import Header from "../components/Header.tsx"
 import HeroSection from "../components/HeroSection.tsx"
 import {
   GlassdoorLogo,
   LinkedinLogo,
   MoreLogo,
   RedditLogo,
 } from "../components/Logo.tsx"
 import React from "react"
 // import { useAppDispatch } from "../app/hooks"
 import { NavLink } from "react-router-dom"
 import winterWymanLogo from "../assets/Winter-Wyman-logo-blue.png";
 import steppingStoneLogo from "../assets/stepping-stone-logo.png";
 import secondStartLogo from "../assets/secondstart.png";
 import AllanZheng from "../assets/AllanZheng.jpg";
 import MarkDonne from "../assets/MarkDonne.jpg";
 
 const data_sources = [
   { name: "LinkedIn", logo: <LinkedinLogo /> },
   { name: "Reddit", logo: <RedditLogo /> },
   { name: "Glassdoor", logo: <GlassdoorLogo /> },
   { name: "More", logo: <MoreLogo /> },
 ]
 const partners = [
   {
     name: "WinterWyman",
     site: "https://theplanetgroup.com/winter-wyman-is-now-planet-professional-and-planet-technology/",
     logo: winterWymanLogo,
     description:
       "Once MVP is developed, the demo of product will be provided to staff at Winter Wyman - aiming to increase outreach response rates for staff members",
   },
   {
     name: "Stepping Stone Advocacy",
     site: "https://training.steppingstoneadvocacy.com/?affiliates=2",
     logo: steppingStoneLogo,
     description:
       "Stepping Stone Advocacy provides training and support for Nurses looking to pivot to new careers, away from the bedside.",
   },
   {
     name: "Second Start",
     site: "https://second-start.org/",
     logo: secondStartLogo,
     description:
       "Second Start offers educational programs and services to help individuals achieve their goals.",
   },
 ]
 
 export default function HomePage() {
   return (
     <>
       <Header />
       <section>
         <Box
           sx={{
             position: "relative",
             minHeight: "20vh",
             py: 8,
             backgroundImage: `url(${heroBg})`,
             backgroundSize: "cover",
             backgroundPosition: "center",
             backgroundRepeat: "no-repeat",
             "::before": {
               content: '""',
               position: "absolute",
               top: 0,
               left: 0,
               width: "100%",
               height: "100%",
               backgroundColor: "rgba(0, 0, 0, 0.5)",
               zIndex: 1,
             },
           }}
         >
         <HeroSection />
 
         </Box>
       </section>
       <Divider />
       <section>
       <section>
         <Typography variant="h2" sx={{ textAlign: "center", py: 5 }}>
           Why CareerGPT?
         </Typography>
 
         <Typography variant="h4" sx={{ textAlign: "center", py: 5 }}>
           We use agentic AI to automate entire workflows for you, from applying to jobs to sourcing candidates.
         </Typography>
         <Carousel />
 
         <Typography variant="h2" sx={{ textAlign: "center", py: 5 }}>
           Our Solution
         </Typography>
         <Grid2 container sx={{ gap: 5, justifyContent: "center" }}>
           <CustomizedCard
             icon="search"
             text="Easy auto-apply on LinkedIn"
           />
           <CustomizedCard
             icon="analytics"
             text="Easy auto-sourcing"
           />
           <CustomizedCard
             icon="explore"
             text="A one-stop shop for all your needs"
           />
         </Grid2>
         {/* <ComparationTable /> */}
       </section>
       <Divider />
       <Divider sx={{ my: 5 }} />
 
       <Divider sx={{ my: 5 }} />
       <Typography variant="h2" sx={{ textAlign: "center", py: 5 }}>
         How Does CareerGPT Work?
       </Typography>
       <HowToUse />
       <Box sx={{ display: "flex", justifyContent: "center", py: 5 }}>
         <Button
           variant="contained"
           sx={{
             backgroundColor: "#5fcf80",
             "&:hover": {
               backgroundColor: "#56ba73",
             },
             borderRadius: "20px",
             margin: "auto",
           }}
           component="a"
             href="/signup"
             target="_blank"
             rel="noopener noreferrer"
         >
           Try it now!
         </Button>
       </Box>
 
         <Typography variant="h2" sx={{ textAlign: "center", py: 5 }}>
           Powered by Advanced AI and Data Sources
         </Typography>
 
         <Grid2
           container
           sx={{ gap: 5, justifyContent: "center", alignItems: "center" }}
         >
           {data_sources.map(source => (
             <Grid2 size={{ xs: 6, sm: 4, md: 2 }} key={source.name}>
               <Box
                 sx={{
                   marginX: "auto",
                   display: "flex",
                   flexDirection: "column",
                   alignItems: "center",
                   gap: 1,
                   justifyContent: "center",
                   "&:hover .data-source-name": { opacity: 1 },
                   "&:hover .data-source": { transform: "translateY(0)" },
                 }}
               >
                 {React.cloneElement(source.logo, {
                   sx: {
                     width: source.name === "Glassdoor" ? 120 : 140,
                     height: "auto",
                     transform: "translateY(10px)",
                     transition: "all 0.5s",
                   },
                   className: "data-source",
                 })}
                 <Typography
                   variant="h6"
                   sx={{
                     textAlign: "center",
                     opacity: 0,
                     transition: "all 0.5s",
                   }}
                   className="data-source-name"
                 >
                   {source.name}
                 </Typography>
               </Box>
             </Grid2>
           ))}
         </Grid2>
 
         <Typography variant="h2" sx={{ textAlign: "center", py: 5 }}>
           Design Partners Of
         </Typography>
 
         <Grid2
           container
           sx={{
             gap: 5,
             justifyContent: "center", // Ensures items are centered horizontally
             alignItems: "center", // Ensures items align properly
             flexDirection: "row", // Ensures the items align horizontally
             flexWrap: "wrap", // Allows images to wrap to the next line on smaller screens
           }}
         >
           {partners.map(partner => (
             <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={partner.name}>
               <Box
                 sx={{
                   width: "100%",
                   marginX: "auto",
                   display: "flex",
                   flexDirection: "column",
                   alignItems: "center",
                   justifyContent: "center",
                   "&:hover .design-partner": { transform: "translateY(0)" },
                   "&:hover .design-partner-name": { opacity: 1 },
                 }}
               >
                 <NavLink to={partner.site} target="_blank">
                   <Box
                     component="img"
                     src={partner.logo}
                     className="design-partner"
                     alt={partner.name}
                     sx={{
                       width: "100%", // Ensure the image takes up the full width of the container
                       height: "auto", // Maintain the aspect ratio of the images
                       maxWidth: "200px", // Set a maximum width for larger screens
                       transform: "translateY(10px)",
                       transition: "all 0.5s",
                     }}
                   />
                 </NavLink>
 
                 <Typography
                   variant="h6"
                   className="design-partner-name"
                   sx={{ my: 2, opacity: 0, transition: "all 0.5s" }}
                 >
                   {partner.name}
                 </Typography>
                 <Typography>{partner.description}</Typography>
               </Box>
             </Grid2>
           ))}
         </Grid2>
 
         <Grid2
           container
           sx={{ gap: 5, justifyContent: "center", marginTop: 5 }}
         >
           <CustomizedCard icon="time" title="60%" text="Time saved" />
           <CustomizedCard icon="money" title="75%" text="Cost saved" />
           <CustomizedCard icon="growth" title="40%" text="Efficiency" />
         </Grid2>
       </section>
       
       <Divider sx={{ my: 5 }} />
       <Typography variant="h2" sx={{ textAlign: "center", py: 5 }}>
         What do the Founders Say?
       </Typography>
       <Box
         sx={{
           display: "flex",
           flexDirection: "column",
           justifyContent: "center",
           gap: 1.5,
         }}
       >
         <Card
           sx={{
             display: "flex",
             flexDirection: { xs: "column", md: "row" },
             maxWidth: "50rem",
             borderRadius: "20px",
             justifyContent: "center",
             alignItems: "center",
             padding: "1rem 2rem",
             marginX: { md: "auto", xs: "2rem" },
             backgroundColor: "#dff5e6",
           }}
         >
           <CardMedia
             component="img"
             sx={{ width: "200px", height: "auto", borderRadius: "50%" }}
             src={AllanZheng}
             alt="Allan Zheng"
           />
           <CardContent
             sx={{ display: "flex", flexDirection: "column", gap: 1 }}
           >
             <Typography variant="h5">
               “Agentic AI could have 100Xed my job search when I was looking for my first software engineering role.”
             </Typography>
             <Typography variant="h6">--Allan Zheng</Typography>
             <Typography>
               Founder/Developer, MS CS Georgia Tech (Interactive Intelligence),
               Software Engineer at Systems Technology and Research
             </Typography>
           </CardContent>
         </Card>
 
         <Card
           sx={{
             display: "flex",
             flexDirection: { xs: "column", md: "row" },
             maxWidth: "50rem",
             marginTop: " 1rem 2rem",
             borderRadius: "20px",
             justifyContent: "center",
             alignItems: "center",
             padding: "1rem 2rem",
             marginX: { md: "auto", xs: "2rem" },
             backgroundColor: "#dff5e6",
           }}
         >
           <CardMedia
             component="img"
             sx={{ width: "200px", height: "auto", borderRadius: "50%" }}
             src={MarkDonne}
             alt="Mark Donne"
           />
           <CardContent sx={{ display: "flex", flexDirection: "row" }}>
             <Stack>
               <Typography variant="h5">
                 "There's a lot of brokenness inherent in the job search space. I'm excited to see how agentic AI can fix this!”
               </Typography>
               <Typography variant="h6">--Mark Donne</Typography>
               <Typography>
                 Startup Advisor, BA in New Media, Marketing, Media Production
                 Manager at Neo Consulting, CEO of theseeker.ai
               </Typography>
             </Stack>
           </CardContent>
         </Card>
       </Box>
     </>
   )
 }