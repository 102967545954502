import * as React from "react"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { useMediaQuery } from "@mui/material"

const steps = ["Enter your search query", "Agent treats it as a task", "Tasks are broken down into steps and executed!"]
const descriptions = [
  "We are currently focusing on using agentic AI to intelligently automate job searching on LinkedIn. We will turn out attention to other areas of the HR pipeline soon.",
]

export default function HowToUse() {
  const [activeStep, setActiveStep] = React.useState(0)
  const matches = useMediaQuery("(min-width:600px)")

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <Box
      sx={{
        maxWidth: "50rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "space-around",
        justifyContent: "space-around",
        margin: "auto",
        padding: "2rem",
        height: { md: "250px", sm: "300px", xs: "auto" },
      }}
    >
      <Stepper
        activeStep={activeStep}
        orientation={matches ? "horizontal" : "vertical"}
        sx={{
          flex: "0 1 auto",
          "& .MuiStepLabel-label": {
            color: "#777", // Default label color
          },
          "& .MuiStepLabel-label.Mui-active": {
            color: "#5fcf80", // Active label color
          },
          "& .MuiStepLabel-label.Mui-completed": {
            color: "#5fcf80", // Completed label color
          },
          "& .MuiStepIcon-root": {
            color: "#777", // Default icon color
          },
          "& .MuiStepIcon-root.Mui-active": {
            color: "#5fcf80", // Active icon color
          },
          "& .MuiStepIcon-root.Mui-completed": {
            color: "#5fcf80", // Completed icon color
          },
        }}
      >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                <Typography variant="h6">{label}</Typography>
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>

      <Typography sx={{ mt: 2, mb: 1, flex: "1 1 auto" }}>
        {descriptions[activeStep]}
      </Typography>
      <Box
        sx={{ display: "flex", flex: "0 1 auto", flexDirection: "row", pt: 2 }}
      >
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />

        {steps.length - 1 !== activeStep && (
          <Button onClick={handleNext} sx={{ color: "#5fcf80" }}>
            Next
          </Button>
        )}
      </Box>
    </Box>
  )
}
