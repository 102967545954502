import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import UserCardSmall from "./UserCardSmall";
import ActiveUserCard from "./ActiveUserCard";
import { useQuery } from "@tanstack/react-query";
import {
   getAllRecruiterAndCoach,
   getAllUsers,
   getMe,
} from "../../services/candidateApi";
import CustomizedSpinner from "./../../ui/CustomizedSpinner";
import { useGetMe } from "../../hooks/useGetMe";

import { useGetAllUsers } from "../../hooks/useGetAllUsers";

function ConnectToProfessionals() {
   const [searchKeyword, setSearchKeyword] = useState("");
   const [searchResult, setSearchResult] = useState([]);
   // const [onlineRecruiters, setOnlineRecruiters] = useState([]);
   const [sortType, setSortType] = useState("name"); // Default sorting by name

   // Function to toggle the viewAllUsers state

   const { myData, isPending: gettingMe } = useGetMe();

   const { allUsers, isPending: loadingAllUsers } = useGetAllUsers();

   useEffect(() => {
      if (allUsers && allUsers.length > 0) {
         applySorting(allUsers); // Initial sort when data loads
      }
   }, [allUsers]);

   const handleSearch = (e) => {
      setSearchKeyword(e);
      const filteredData = allUsers.filter((rec) =>
         rec.name.toLowerCase().includes(e.toLowerCase())
      );
      // applySorting(filteredData);

      const sortedData = [...filteredData].sort((a, b) => {
         switch (sortType) {
            case "experience":
               return b.experience - a.experience;
            case "rating":
               return b.rating - a.rating;
            default:
               return a.name.localeCompare(b.name);
         }
      });
      setSearchResult(sortedData);
   };

   const applySorting = (data) => {
      const sortedData = [...data].sort((a, b) => {
         switch (sortType) {
            case "experience":
               return b.experience - a.experience;
            case "rating":
               return b.rating - a.rating;
            default:
               return a.name.localeCompare(b.name);
         }
      });
      setSearchResult(sortedData);
   };

   const handleSortChange = (key) => {
      setSortType(key);
      // applySorting(searchResult);
      const sortedData = [...searchResult].sort((a, b) => {
         switch (key) {
            case "experience":
               return b.experience - a.experience;
            case "rating":
               return b.rating - a.rating;
            default:
               return a.name.localeCompare(b.name);
         }
      });
      setSearchResult(sortedData);
   };

   const online =
      allUsers?.filter((allUsers) => allUsers.isOnline === true) || [];
   const [viewAllUsers, setViewAllUsers] = useState(false);
   const [viewAllActiveUsers, setViewAllActiveUsers] = useState(false);

   const toggleViewAll = () => {
      setViewAllUsers(!viewAllUsers);
   };

   if (loadingAllUsers || gettingMe) {
      return <CustomizedSpinner />;
   }

   return (
      <div className=" d-sm-flex justify-content-sm-center   mt-sm-5  px-4 w-100   ">
         <div className="col-md-11 col-sm-10  col-xl-10 col-xxl-9  gap-3  d-grid ">
            <div className=" fw-medium ">
               Select professional and start chat
            </div>
            <div className="d-flex  justify-content-between mb-2 ">
               <div className="input-group  w-50  ">
                  <input
                     className=" mt-1 py-2 w-100   fs-sm px-2 px-sm-4"
                     placeholder="Search professional"
                     value={searchKeyword}
                     style={{
                        border: "2px solid #DADBDA",
                        borderRadius: "14px",
                     }}
                     onChange={(e) => handleSearch(e.target.value)}
                     onFocus={(e) =>
                        (e.target.style.border = "2px solid #525252")
                     }
                     onBlur={(e) =>
                        (e.target.style.border = "2px solid #DADBDA")
                     }
                  />
                  <div className="input-group-append ">
                     <span
                        style={{
                           cursor: "pointer",
                           position: "absolute",
                           right: "10px",
                           top: "9px",
                        }}
                        onClick={handleSearch}
                     >
                        <FiSearch color="#9D9E9D" />
                     </span>
                  </div>
               </div>
               <div>
                  <Dropdown onSelect={handleSortChange}>
                     <Dropdown.Toggle
                        value={sortType}
                        className=" text-black w-100  mt-1 py-2 w-100   fs-sm px-2 px-sm-4 bg-white d-flex justify-content-between align-items-center font fs-sm "
                        style={{
                           border: "2px solid #DADBDA",
                           borderRadius: "14px",
                        }}
                     >
                        {sortType ? sortType : "Sort by"}
                     </Dropdown.Toggle>
                     <Dropdown.Menu className=" w-100 border-2  ">
                        <Dropdown.Item eventKey="name">Name</Dropdown.Item>
                        <Dropdown.Item eventKey="rating">Rating</Dropdown.Item>
                        <Dropdown.Item eventKey="experience">
                           Experience
                        </Dropdown.Item>
                     </Dropdown.Menu>
                  </Dropdown>
               </div>
            </div>

            <div className="d-flex justify-content-between   ">
               <div className=" fw-medium ">Active users</div>
               <button
                  className={`border-0 bg-body fw-medium text-${myData.theme} p-0`}
                  style={{ fontSize: "14px" }}
                  onClick={() => setViewAllActiveUsers(!viewAllActiveUsers)}
               >
                  View All
               </button>
            </div>
            <div className="row flex-sm-row p-2 d-sm-flex d-none">
               {online.length == 0 && (
                  <div className="fs-sm">currently no any active users </div>
               )}
               {viewAllActiveUsers
                  ? online?.map((allUsers) => {
                       if (allUsers.id !== myData.id) {
                          return (
                             <ActiveUserCard
                                userType={allUsers.userType}
                                userId={allUsers.id}
                                key={allUsers.id}
                                name={allUsers.name}
                                role={allUsers.industry}
                                country={allUsers.country}
                                experience={
                                   allUsers.experience
                                      ? `${allUsers.experience}Y exp.`
                                      : ""
                                }
                                profilePic={allUsers.image}
                                rating={allUsers.rating}
                             />
                          );
                       }
                    })
                  : online?.slice(0, 4).map((allUsers) => {
                       if (allUsers.id !== myData.id) {
                          return (
                             <ActiveUserCard
                                userType={allUsers.userType}
                                userId={allUsers.id}
                                key={allUsers.id}
                                name={allUsers.name}
                                role={allUsers.industry}
                                country={allUsers.country}
                                experience={
                                   allUsers.experience
                                      ? `${allUsers.experience}Y exp.`
                                      : ""
                                }
                                profilePic={allUsers.image}
                                rating={allUsers.rating}
                             />
                          );
                       }
                    })}
            </div>
            <div className=" p-2 d-flex d-sm-none overflow-x-scroll  ">
               {online.length == 0 && (
                  <div className="fs-sm">currently no any active users </div>
               )}
               {viewAllActiveUsers
                  ? online?.map((allUsers) => {
                       if (allUsers.id !== myData.id) {
                          return (
                             <UserCardSmall
                                rating={allUsers.rating}
                                userType={allUsers.userType}
                                userId={allUsers.id}
                                key={allUsers.id}
                                name={allUsers.name}
                                role={allUsers.industry}
                                country={allUsers.country}
                                experience={
                                   allUsers.experience
                                      ? `${allUsers.experience}Y exp.`
                                      : ""
                                }
                                profilePic={allUsers.image}
                             />
                          );
                       }
                    })
                  : online?.slice(0, 4).map((allUsers) => {
                       if (allUsers.id !== myData.id) {
                          return (
                             <UserCardSmall
                                rating={allUsers.rating}
                                userType={allUsers.userType}
                                userId={allUsers.id}
                                key={allUsers.id}
                                name={allUsers.name}
                                role={allUsers.industry}
                                country={allUsers.country}
                                experience={
                                   allUsers.experience
                                      ? `${allUsers.experience}Y exp.`
                                      : ""
                                }
                                profilePic={allUsers.image}
                             />
                          );
                       }
                    })}
            </div>
            <div className="d-flex  justify-content-between mt-2 ">
               <div className=" fw-medium ">All users</div>
               {searchResult && searchResult.length > 4 && (
                  <button
                     className={`border-0 bg-body fw-medium text-${myData.theme} p-0`}
                     style={{ fontSize: "14px" }}
                     onClick={toggleViewAll}
                  >
                     {viewAllUsers ? "Close" : "View All"}
                  </button>
               )}
            </div>
            <div className="row flex-row p-2 mb-3">
               {searchResult && searchResult.length ? (
                  (viewAllUsers ? searchResult : searchResult.slice(0, 4)).map(
                     (allUsers) => {
                        if (allUsers.id !== myData.id) {
                           return (
                              <ActiveUserCard
                                 rating={allUsers.rating}
                                 userType={allUsers.userType}
                                 userId={allUsers.id}
                                 key={allUsers.id}
                                 name={allUsers.name}
                                 role={allUsers.industry}
                                 country={allUsers.country}
                                 experience={
                                    allUsers.experience
                                       ? `${allUsers.experience}Y exp.`
                                       : ""
                                 }
                                 profilePic={allUsers.image}
                                 theme={myData.theme}
                              />
                           );
                        }
                        return null;
                     }
                  )
               ) : (
                  <div className="col-12">No matching professionals found.</div>
               )}
            </div>
         </div>
      </div>
   );
}

export default ConnectToProfessionals;
