import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { 
  getQuestions,
  getAutoApplyProfile, 
  saveAutoApplyProfile,
  submitAutoApplyForm,
  startAutoApply 
} from "../services/autoApply";
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faBriefcase, faCertificate, faTrophy, faClipboard } from '@fortawesome/free-solid-svg-icons';

const AutoApply = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
    padding: '20px',
    backgroundColor: '#e6f2e6', // Light green background
    color: '#2e7d32',           // Darker green text
    fontFamily: 'Arial, sans-serif',
  };

  const titleStyle = {
    fontSize: '2.5rem',
    marginBottom: '1rem',
    color: '#1b5e20', // Even darker green for the title
  };

  const textStyle = {
    fontSize: '1.25rem',
    maxWidth: '600px',
    textAlign: 'center',
    lineHeight: 1.6,
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Coming Soon</h1>
      <p style={textStyle}>
        We’re working on something exciting! Stay tuned for updates.
      </p>
    </div>
  );
};

export default AutoApply;
