import { Children } from "react";
import { useGetMe } from "../hooks/useGetMe";
import { useLocation, Navigate, Outlet } from "react-router-dom";

function RouteProtecter({ authorizedUserType, children }) {
   const { myData, isPending } = useGetMe();

   if (isPending) return null;

   if (!myData?.userType) {
      console.log(myData?.userType);
      return <Navigate to="/" />;
   }

   // return <>{myData.userType == authorizedUserType && children}</>;
   if (myData.userType == authorizedUserType) {
      return <>{children}</>;
   } else {
      return <Navigate to={`/${myData.userType}layout`} />;
   }
}

export default RouteProtecter;
