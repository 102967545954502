import { Box, Pagination, Stack, Typography } from "@mui/material"
import { useState } from "react"
import carousel1 from "../assets/carousel1.jpg";
import carousel2 from "../assets/carousel2.jpg";
import carousel3 from "../assets/carousel3.jpg";

const carouselConent = [
  {
    imageUrl: carousel1,
    imageDescription: "LinkedIn logo",
    title: "Manually filling out job applications is tedious",
    description: [
      "Imagine how much time you could save if an AI Agent took care of this for you!",
    ],
  },
  {
    imageUrl: carousel2,
    imageDescription: "An angry man calling to others",
    title: "Sourcing on LinkedIn has low lead conversion rates, so why not auto-source?",
    description: [
      "Jamie Chafel, VP of Winter Wyman, once said that only 3% of his messages even get a reply",
      "lots of wasted time and inefficiency",
    ],
  },
  {
    imageUrl: carousel3,
    imageDescription: "Lots of puzzles",
    title: "Fragmentation of career resources",
    description: [
      "Too many career resources out there and it can feel overwhelming",
      "Centralize everything into a one-stop shop",
    ],
  },
]

export default function Carousel() {
  const [carousel, setCarousel] = useState<number>(1)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "2rem",
      }}
    >
      {carouselConent.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: index === carousel - 1 ? "flex" : "none",
            flexDirection: { xs: "column", md: "row" },
            padding: "0",
            width: { xs: "200px", md: "40rem" },
            height: { md: "200px", xs: "auto" },
            alignItems: "center",
            justifyContent: "center",
            gap: "2rem",
            marginX: "2rem",
            borderRadius: "10px",
            boxShadow: "0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
          }}
        >
          <Box
            component="img"
            src={item.imageUrl}
            alt={item.imageDescription}
            sx={{
              width: "200px",
              height: "auto",
              borderRadius: { sm: "10px  10px 0 0", md: "10px" },
              transition: "all 0.5s",
              overflow: "hidden",
            }}
          />

          <Stack sx={{ padding: "1rem", width: { xs: "100%", md: "60%" } }}>
            <Typography variant="h5">{item.title}</Typography>
            {item.description.map((desc, index) => (
              <Typography key={index}>{desc}</Typography>
            ))}
          </Stack>
        </Box>
      ))}

      <Pagination
        page={carousel}
        count={3}
        onChange={(e, value) => setCarousel(value)}
      />
    </Box>
  )
}
