import React, { useState, useEffect } from "react";
import ChatInterface from "../components/ChatInterface";
import axios from "axios";
import { baseUrl } from "../services/api";
import { Typewriter } from "react-simple-typewriter";
import Trends from "../components/Trends";
import "../css/salary.css";
import { useQuery } from "@tanstack/react-query";
import { newsData } from "../services/candidateApi";
import CustomizedSpinner from "../ui/CustomizedSpinner";
import ChatInterfaceForNetworking from "../components/ChatInterfaceForNetworking";
import { useGetMe } from "../hooks/useGetMe";
import UserCardForMatching from "./connectToProfessionals/UserCardForMatching";
import SearchForm from "../components/SearchForm.tsx"; // Import SearchForm
import PeopleCard from "../components/PeopleSearch/PeopleCard.jsx"; // Import PeopleCard

function RecruiterCandidateMatching() {
  const { myData, isPending: gettingMe } = useGetMe();
  const [chatMessages, setChatMessages] = useState([
    {
      bot: "What would you like to search for? Candidates or Recruiters?",
    },
  ]);
  const [viewAllUsers, setViewAllUsers] = useState(false);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [newsPanelVisible, setNewsPanelVisible] = useState(true);
  const [uploadVisible, setUploadVisible] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [searchRecruiterIndustry, setSearchRecruiterJobTitle] = useState("");
  const [searchRecruiterCountry, setSearchRecruiterCountry] = useState("");
  const [searchResults, setSearchResults] = useState([]); // Add state for search results

  const closeNewsPanel = () => {
    setNewsPanelVisible(false);
  };

  const hideNewsPanel = () => {
    setNewsPanelVisible(false);
  };

  const handleSearch = async (query) => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/search`, {
        params: { query },
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-sm-flex justify-content-sm-center pt-4 pt-xl-3 px-4 w-100 align-content-center">
      <div
        className="px-sm-5 gap-3 col-12 d-grid align-content-between"
        style={{
          height: isMobile ? "83.8vh" : "85vh",
          overflowY: "auto",
        }}
      >
        <div className="d-flex justify-content-between mt-2">
          <div className="fw-medium">Search for Professionals</div>
          <SearchForm handleSearch={handleSearch} /> {/* Add SearchForm */}
        </div>
        <div className="row flex-row p-2 mb-3">
          {loading ? (
            <CustomizedSpinner />
          ) : (
            searchResults.map((result) => (
              <PeopleCard key={result.id} {...result} /> // Use PeopleCard to display results
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default RecruiterCandidateMatching;