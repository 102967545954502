import { useQuery } from "@tanstack/react-query";
import { getMe } from "../services/candidateApi";

export const useGetMe = () => {
   const { data: myData, isPending } = useQuery({
      queryKey: ["getMe"],
      queryFn: getMe,
   });

   return { myData, isPending };
};
