import { createAppSlice } from "../../app/createAppSlice.ts";

interface AuthDialogState {
   isOpen: boolean;
}

const initialState: AuthDialogState = {
   isOpen: false,
};

export const authDialogSlice = createAppSlice({
   name: "authDialog",
   initialState,
   reducers: (create) => ({
      openDialog: create.reducer((state) => {
         state.isOpen = true;
      }),
      closeDialog: create.reducer((state) => {
         state.isOpen = false;
      }),
      toggleDialog: create.reducer((state) => {
         state.isOpen = !state.isOpen;
      }),
   }),
   selectors: {
      selectIsOpen: (authDialog) => authDialog.isOpen,
   },
});

export const { openDialog, closeDialog, toggleDialog } =
   authDialogSlice.actions;
export const { selectIsOpen } = authDialogSlice.selectors;

export default authDialogSlice.reducer;
