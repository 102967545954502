import {
   Grid2,
   TextField,
   Divider,
   Typography,
   Button,
   Box,
   Chip,
   Stack,
 } from "@mui/material"
 import { useState } from "react"
 import { toast } from "react-toastify"
 
 const input_chips = [
   "Python developers in the Boston area",
   "Professionals with Airframe and Powerplant (A&P)  Certification",
   "Sales professionals who have knowledge of Salesforce",
 ]
 
 export default function SearchForm({
   handleModalOpen,
   available,
 }: {
   handleModalOpen: () => void
   available: boolean
 }) {
   const [searchContent, setSearchContent] = useState<string>(
     "Python developers in the Boston area",
   )
 
   function handleSearch() {
     if (searchContent === "") {
       toast.error("Please enter a search query")
       return
     } else if (available) {
       window.location.href = `/search?query=${encodeURIComponent(searchContent)}`
     }
   }
 
   return (
     <Grid2
       container
       sx={{
         position: "relative",
         minHeight: "5vh",
         py: 3,
         zIndex: 2,
         alignItems: "center",
         justifyContent: "center",
       }}
     >
       <Typography
         variant="h6"
         sx={{
           color: "#effaf2",
           textAlign: "center",
           fontWeight: "600",
           fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
           marginX: "8rem",
           marginTop: '8rem',
         }}
       >
         Experience CareerGPT's{" "}
         <span style={{ color: "#5fcf80" }}>Agentic_AI</span>
         <br />
          to Automate Your Professional Workflows
       </Typography>
       <Stack spacing={2} width="60%" alignItems="center">
       <Divider sx={{ my: 5 }} /> 
       <Button
           variant="contained"
           href="/signup"
           sx={{
             borderRadius: "20px",
             marginBottom: "10px",
             padding: "6px 12px",
             width: "150px",
             fontSize: "0.875rem",
             backgroundColor: "#5fcf80",
             "&:hover": {
               backgroundColor: "#56ba73",
             },
           }}
         >
           Sign Up
         </Button>
         <Button
           variant="outlined"
           href="/signin"
           sx={{
             borderRadius: "20px",
             padding: "6px 12px",
             fontSize: "0.875rem",
             width: "150px",
             color: "#5fcf80",
             borderColor: "#5fcf80",
             "&:hover": {
               backgroundColor: "#e8f5e9",
               borderColor: "#56ba73",
             },
           }}
         >
           Sign In
         </Button>
         <Grid2
           columns={{ xs: 1, sm: 2, md: 2 }}
           spacing={2}
           container
           sx={{
             maxWidth: "80rem",
             justifyContent: "center",
             justifyItems: "center",
             alignItems: "center",
           }}
         >
           <Grid2 width={{ xs: "100%", sm: "100%", md: "80%" }}>
             {/* <TextField
               hiddenLabel
               multiline
               error={searchContent === ""}
               onChange={e => {
                 setSearchContent(e.target.value)
               }}
               value={searchContent}
               variant="outlined"
               onFocus={e => e.target.select()}
               sx={{
                 backgroundColor: "white",
 
                 width: "100%",
                 borderRadius: "20px",
                 "& .MuiOutlinedInput-root": {
                   borderRadius: "20px",
                   "&.Mui-focused fieldset": {
                     borderRadius: "20px",
                     borderColor: searchContent === "" ? "red" : "#5fcf80", // Change border color on focus
                   },
                 },
               }}
             /> */}
           </Grid2>
           <Grid2
             justifySelf="center"
             flexShrink={0}
             sx={{
               margin: "auto",
             }}
           >
             {/* <Button
               variant="contained"
               size="large"
               sx={{
                 marginLeft: "auto",
                 backgroundColor: "#5fcf80",
                 "&:hover": {
                   backgroundColor: "#56ba73",
                 },
                 borderRadius: "20px",
                 border: "none",
                 fontSize: "1.2rem",
                 margin: "0 auto",
               }}
               onClick={() => {
                 handleModalOpen()
                 handleSearch()
               }}
             >
               Search
             </Button> */}
           </Grid2>
         </Grid2>
         {/* <Box
           sx={{
             display: "flex",
             justifyContent: "center",
             alignItems: "center",
             flexDirection: "column",
             gap: "10px",
           }}
         >
           {input_chips.map(chip => (
             <Chip
               label={chip}
               variant="outlined"
               key={chip}
               onClick={() => {
                 setSearchContent(chip)
               }}
               sx={{
                 color: "#effaf2",
                 borderColor: "#5fcf80",
                 width: "100%",
                 borderRadius: "10px",
                 fontSize: "1rem",
               }}
             />
           ))}
         </Box> */}
       </Stack>
     </Grid2>
   )
 }