import React from "react";

import FileUpload from "../components/FileUpload";
import FocusBarCandidate from "../components/FocusBarCandidate";
import Style from "./../css/candidateNavBar.module.css";
import "./../css/RecruiterLayout.css";

import UserNavbar from "../ui/UserNavbar";
import { useGetMe } from "../hooks/useGetMe";
import CustomizedSpinner from "../ui/CustomizedSpinner";
// import Spinner from "../ui/Spinner";
// import { getMe } from "../services/candidateApi";
// import { useQuery } from "@tanstack/react-query";
// import CandidateNavBar from "../ui/CandidateNavBar";
const CandidateLayout = ({ children, isNewChat = false }) => {
  const uploadButton = <FileUpload className="mt-2 d-flex" />;
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
  const [isTemparySidebarOpen, setIsTemparySidebarOpen] = React.useState(true);
  // const pathName = window.location.pathname;
  const { myData, isPending: isMyData } = useGetMe();

  if (isMyData) {
    return <CustomizedSpinner />;
  }
  // const { data: myData, isPending: mydataLoading } = useQuery({
  //   queryKey: ["getMe"],
  //   queryFn: getMe,
  // });

  // if (mydataLoading) {
  //   return <Spinner />;
  // }

  // if (pathName == `/${myData.userType}/chat`) {
  //   setIsTemparySidebarOpen(true);
  // } else {
  //   setIsTemparySidebarOpen(false);
  // }

  return (
    <>
      {/* <Header /> */}
      {/* <div className="header-text-container">
        <IoShieldCheckmarkOutline style={{ color: "white" }} />
        <p className="mb-0">CareerGPT was just updated!</p>
      </div> */}
      <div
        className="pt-sm-3 pt-0"
        style={{
          height: "100vh",
          paddingTop: "2vh",
          backgroundColor: "#f5f4f4",
        }}
      >
        <div>
          <div className="d-flex ">
            <div
              style={{
                // border: "1px solid #ff0000",
                backgroundColor: "#f5f4f4",
              }}
              className={` d-md-block d-none ${
                isSidebarOpen && isTemparySidebarOpen
                  ? "col-12 col-md-1 col-lg-3 col-xxl-2"
                  : "col-12 col-md-1 col-lg-1"
              }  `}
            >
              <FocusBarCandidate
                isNewChat={isNewChat}
                isSidebarOpen={isSidebarOpen}
                isTemporySidebarOpen={isTemparySidebarOpen}
              />
            </div>

            <div
              style={{
                backgroundColor: "#fff",
                height: "96vh",
                borderRadius: "20px",
              }}
              className={`${
                isSidebarOpen && isTemparySidebarOpen
                  ? "col-12 col-md-11 col-lg-9 col-xxl-10"
                  : "col-12 col-md-11 col-lg-11"
              }  d-flex  flex-column position-relative main-layout`}
            >
              <UserNavbar
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isTemparySidebarOpen={isTemparySidebarOpen}
                setIsTemparySidebarOpen={setIsTemparySidebarOpen}
              />
              <div
                className=""
                style={{ height: "100vh", overflow: " scroll" }}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateLayout;


