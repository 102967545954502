import SvgIcon from "@mui/material/SvgIcon"
import type { SvgIconProps } from "@mui/material/SvgIcon"
import logo from '../assets/logo.png'

export default function Logo(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="282px"
        height="180px"
        viewBox="0 0 282 180"
        enableBackground="new 0 0 282 180"
        xmlSpace="preserve"
      >
        <image
          id="image0"
          x="0"
          y="0"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARoAAAC0CAYAAABL0uOQAAAAIGNIUk0AAHomAACAhAAA+gAAAIDo
AAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAD/AP8A/6C9p5MAACZYSURBVHja7Z15fFxV3f8/
33Nnsk1KSxcLNJRSSpOZVLSUXREKIiWZtCxPo4hsPlr0QcRCZgooOogPpZnWokX40Z+KIG5BkTaT
UArSgspexNJmEoi0QFm7p9ln7vk+f6RLmibpLPfOuZOc90vpK3c553NOJp8599xzvl+CRqPRJICv
LnAUE30Hkr+/99BLLLGgcXb42cPdS6rFazQa5/PpyC2T4zA3AMjve85Arm+D/yfRwe4Xqhug0Wic
TWkkODMOswH9mAwAmNy1wVd/y/TBytBGo9FoBqSoZn4+M98HIHfAiwiCpbxzsHK00Wg0mgEpzHdF
mFBy+Cu5vKS26syBzmqj0Wg0/XLMA6ECInwh8TvEVwY8o7oxGo3GeZy7JuQaOaFtJwBXovcQ+IqB
zmmj0Wg0h/BxR7sfQE5SNxHGlDx2/Zj+Tmmj0Wg0hyL5/6dyG+Xk/6q/49poNBrNQZTWBWYBGJva
3TStv6PaaDQazUGYjK+lfDMhZ0r9DYe8CtdGo9FoDoKAr6Rx++gC5I/re1AbjUaj2Y9vZdUcAEbK
BTA8bBiFfQ9ro9FoNPthIe5Pu4yYecgeyoTfkWs0ymHQjHXzXLHtR+SInEJ3d3tbHkPmQoocFma/
G4Q5bowzXDyByRgDaY4F0zFMOIYADwgeAHm9Lo+DsRvAJwx+n4i2EHhzXMrNQqBj30UmczwPuTtL
2k9uebSy0lTdLVYxbcVt403EPHaUrY1Go5zJNQtG5uZ1nUDCfQIIJ0um6UQ8CUwHm0cdU3vPZzYH
sbYcgHMBkQtCDrHRr9GQATATwBIA7ftfDzywJgIBDDAIBhkHXesC4nGYO98oeGWPNxKMAxxn5vtz
cvDg+gsXt6nuz1SJG7KIgCPSLoflqL7HtNFo0uL4p28bXxCTE00ZP5FYlBC4oPd5BgqI8SkmHMPA
sQSeAPQxEEgA7p6/ZQao5y8cgzoBAIVRTlwAxhEwbp9GIloWi2GZN1LFAL0I0EtM/HGcOpc2ly3r
UiU0GQTMCw/X4wlBPBXAC307TDOcYabilQsKTTdG5jCPlZJHHTjpAsEcT4JKWPJZAJ0F4oMn+jpj
6BkrEADu1xp4rx9Qr/8OXYgAnAnwmcSAm/MWeiOB3cR0PTP+E+3c9AoqH3Xk4xYD/2tJQRJHH9Ir
qhunsZ8ZD8xzdxSNLGXgHEj6LxB/ps8lBgCDARel88ZBczgkmNsZ4tbGiup7VYs5iAfmub0TRnZb
URQRL2woX3xb72P7RzQnPVzl6R5NDwA8VhAVssRWgPeYcN/1ZsXCRtX9oDnA3Joa4995L53sMowv
AnxgJabkESxoHBjHAxi/73A7cOAphAYeHOtvHdsRICok8DJvJHAXGPUAbo9WhN9SLcx77MjLIS0q
TFL/b51KH7/Z123QGgI+BRCYsfdTRzAQv9JbG3hRCNyxsTy8SnWHDAdm1IYKWo226WTycQAAQROJ
qRzgzwPABrwCAwLMfUyD6PDTGhqnMAKELwP4ckld8EEy+d7o7PBrqsSwyZOJrPmqkQK7+x5znbsm
5Pq4rW3joFUQzpCMJ7yRwG6T5ZQ3K5ZsU9UhQ4qauYYP4/LN3EISBn0B4IcAHtOONgiJA3OmCU2M
arIVYr4WAteWRAJ3xUXnj1VMHpMQBWCLPmMktvc9JD5pa7skiSJGGsJ4z1sbuDHTHTGU8NYFfuCN
BNZ4Cya9xAWebcLgFkBGAB6TfumabIWA21wyr9lXd8s1Ga+c+SLLyjLlO30PuRiYlaSgPBDu8dUG
zkeh56sNM0OtGe+ULKJ4RXCEMHAlgycQcB2AMXpwohkIAoqYzQdLIoFp6HYtbLx04fb0S02IaekX
0YOL6L1D2uWNBNL62Jssx+lHqV4waNrqm4rMLkyA4foDmCeplqTJThjYGhd0cnNZ9Ra760rXB3pj
gHwb/NUHpV9Je6+TQSJ6wpM3HWt3R2QDxSurKr11gSYzZjRBGC9ok9GkAwHj3BJNJY/dausjdfHK
quPtbosVmyrH5sSMFw+X12Wo4qurOt1bG7jXGwl0CUF/AnAiBsh/o9EkDxdQTnxbSW3VuXbVIAya
bVlhBO5m85DRkVW7t49hab5WXBc8xa7OcBJT6m/I9T0RPN8bCW5iphdBuB7JxlfVaJKAiNYUR4Iz
bSmcUW5dWdQGyD19D1saJkIw/8Nbd/NxtnSGQyhdFZzplvnb2OSnAf1opMkcArz69PpQ2pse++FI
64ri3WPGYMeh2oEGCwXngsXaGa/Oc9vQGUrx1lZd4o0EXpRxfgbgwvRL1GiSxtXCbdumRYJe1UIG
ofOFs5Z29D0oIOiPFlc0qeOjkZ+Urpo/WnWLraC0LjDLFwn8A0SPAThdtR7NMIfhNgmvOffJof89
LoJN+R/r+wKjpOl6/dw1oazdHT6l/oZcb21wuWQ8wcDnVOvRaPbDnAcWC1XL6FcaZL/blFxE+MSm
Go/9uLXtRwBuV934ZPHWBb4BidtBPFG1Fo2FMLaDeDuBdkHgI2nK90DiE4LYyiQ7GGInAJApXTAw
goAjJdORomexZTH3zGWMB+CEFdyXl9QGOxsrqr+uWkhv3HD9sL/jVFQzP39EgavdtpqlvCo6e8lv
VXdAIkypv3Wcm+NVYARVa9EcDINNAu0A40MGbxBEH/Y+LZl3CKKP44Z8Y0R8xMZ1FSH7PtO98NX8
TyHl589i0MkgymFgPAGXMlCQfumHpc1w53g3XPi/76VcAjN56xesA7Mly1Oi/nD/kQ4BwFsb2ApK
NWHUYRvSKqUobppT/YEt5VvE1Nr5EwS53iK9BsY+CAyJGHri77aAqdekoQQTbSXGJgg8FYPn8eay
UItqyelQUhf8NTE+t/ftpG3LHzq2evI3XxvqTOlma42mIeoPl/Z3omcORchTwGKzLb1AVCgMfhlA
kS3lW0DpisAsSfgztMmkD1MrhHwLLJ4l8GOSzI/3nSJpMITsNnJzO3ft6GjZUnno24mhRGN5z2NN
yRO3TKK4OQeEe+yop2BM69UAHki5ACkPja6aAgR6ZuBzAE56ssoTi9P7YIy0oyP21lQdLQ8vsK38
FPFFgg8weJ5qHU6Egd1EeBeMfzBo/0sDAe6WxNulFM15OXJjNgfkziRFNfPzR3hcXwfjxwCsfCvb
XsC7x62rWJ7S46I3EngFQNqLbZk41Fi++I7+zu23MW9tYC0I51jY+EOQJn+mac7i9XbWkQzeSPAv
AF+qWkdGIcTA2AWgGUAXADDQSUCUYf650f/T51VLHA74IoFnGPgCrAqdSng2Wh6eiRQCF1llNEbc
nLjh4p/2O1+0//WzAdxlwl6jEQY9AuAkO+tIFG8k0ACwkxc+WcEHAD8O4moZN3YAgNHVyi3INbfM
/WkniHTACkU0lFef71t7h4fb2lpgRRRVibMnPRjKTXmuxgIGMhmgl9FsqAiv9kYC2wCbJoV7+LS3
LviLaHn19ao6AwC8dcF68BAwGYYE4TUC7pfMnQTaFTNcrzSXLdx6+JuXqlY/vCHiBqC1uC54msGI
MHh8euVB5I9r+zuAU5U0h+nbg8vrhbc2EARhkc2apDCN4o1z7m5W0SHeusAfwfiyirotYCcRnpCS
tlF3993Ry+75MP0iNaopXll1vCHoObbghQlDntLoX7IumXu8tYGnQTg/nWqlwNlNZeF/DnTBQSt3
Y4brQbeM2200gg1zGQDrQgcmBnkjgb+AkUzoUifQTcBOSHFaw+xF76oWo7GeptmLN/lqQifA0/ZJ
ui9kCPQ1AEkZTc/2Gk7HaLoGMxmgz+7t5rKFW0H4ZZr9dlgYmFVcG7zW7np6U1x/y0wA/kzWmT50
vRGDr8EfPkqbzNCmoTLUzRBnp18SfW/8k1VJ5c+WJtJaUMtMyw93zSFhIsgUdwKwfX2DEMjYK2Vv
pGqakObfAGTDrvIYCD+Z1n6qK+qvvm/DJWHL96JpnElj+aI3CPhuuuUcGaMlyVzfNKd6TzLXHwQh
1lhRfdhkBYcYTcPsRe8yY0O6jT0szGd464IX2F2NryaUw6AnbG+PNWzqdnNRtDx8+6OVlY5Mm6qx
lwZ/eBkYaa2iJ3ByCQd6SO0LjRFJ5LJ+A1/FDc8X02lo4iL54Rm182zdE8Ke9p+Rg1clHxCKhwve
3138nwsX27PJVZM1mJDnA+nkjaTjSupumprUHcQ1qdQUJ/w4kev6NZrmslALAb9JvaEJc1QHRp1p
V+Glq+aPBrOjdrf2B4OuiVaEr1533fKYai0a9bxZsaQRwCvplEHSWJPM9VImP4oi5tq3ysOvJ3Lt
gKE8yRW/GYDtw3cmfho1cy1PLD9pTShPxl0fwNmxfCUEXdLor35ItRCNsxjv8Xwe6YxqCKOTyW7Q
2mH+KtkqWIhliV47oNFsnLV0BzNn5FVwiec4yzNfejpazwKQmwn9qcLAn6IXLVqhWofGeaydGYqD
8es0isgjYRyd6MVbKpd2MPNfEi6deXW0vPqpRC8fNDh5Xjz+dwDdaTQ2MSRZF4V9X5GSfme77jQg
wj8b/eGv6m0AmoEQAs+kcz9B/iGZ6w2TfwhCIo/v/4kZXUmlaBnUaF6/5J5dwhUvhc2PUEQ4b0p9
0LIJ25LaqpsAHGWn5rSRdJNqCRpns7E8/AcGp/O3N3Fq7fcnJFzfxUsawDjcKGWPMOTlzWXLupIR
cth0KxtnLW0GkNKMdDK4ZWqz3v1Bgq62W2+aCu9sqKh+WbUKjfMhiKp07jeM2LeTuT7qD5cDGCjf
dxxE12+8aEnSE9UJ5XVywbgeIFsfoRg41opX3cVPBIvBztghPhBE/JhqDZrsgMDJbSfoi8mfSfYW
4TLOwKFpmD4GeHq0vDqlVcQJGc0b/rt3CiFt3ZtEQFGrHDUq3XKE5Do7dVrQ0B0NCb4S1Gi6Ree/
AaQeWIyS33azcdbdzVF/uFQKmsvMdwjJ50f94aOi/sUpL+RNKg6GNxJ4CcBpafTboDDwWqM/PCPV
+6fWzp9gkLvRyQnenBb8S+N8vJHAh0hjzpEFLmwsC69W2YakUuKO93g+B8ZGu8QQcDI4jSBAMRrr
ZJNhwATEJtU6NNkFsfm1tO6XuEt1G5IymrUzQ3EyjCsBxO0S5I0EUn5EM3KMv9qlywoIeL2pa1NG
0oBohg4NFT/9W5pFjJ60JpSnsg1JGQ0ANJTd/S9Ism1ZP6UYaWxyzYKRABJeCakEQisqH9WbJTWp
8E4a904q7G4Zp1J80kYDANHZ1b+1Yjt7fzCJVHaeItcjHb+nCUhvV65mOEMvpHNzZ0fuCJXqUzIa
AMhnz68AO+Zr+OIUb0wq2I8KTMmPqtagyU6IZRq7uQG3K75SpX5XqjfuTTk6rSebAKwM9J3aJkim
K1PINJFR4nlGWkvKNcMXBq0E8NU0ijih+Imqk0jSiZDcswfQoG1mR7yZ8+PvJ7vSN1nST/MAwFsb
aAdZl+UxJjpHNpctSyodqjcScLbLYOC8xBpNItj/GaetTHQ+x3lz0+yCNlAorVFUb1J+dDqoEHe8
CJxOoJ4+5VFBUit7S2tvSnr1o0aj6QuPI5brhcEtJfVtL5bUBf7mqwlZEmbFEqPZOGvpjmhF2ECq
4QD7ipKU1JsnCUNvUNQMB+ozVRExTiXGeVzQ1uWLBB4orV2Q1pe5JUazj6g/PAWMj9JuJGRSj00Q
OM7Kdmg0joTxJzXVYp4k+bovsuCOksduHZNKGZYaDQDEDE8xQNvTKYNzjdcSv5gJ0tFR9DQaS2Cw
bQtlE6tf/pBy4k0ltVXfTPZey42muSzUEhMdE0D4SaplNH5xYcJGNek3d+Qy4Vir26HROA6DtqmW
AGAMES33RgL3Tam/IeEIlpYbDQA0ly3ripaHbwfRVQB2JnUz4fFkLneN355D4ISD+6ikdNX80ao1
aLKXOFz/Uq2hF9/OMXP/XvxkYnGJbTGafUTLq39bwLuLQHgv0XsIIqnwg3vvyorXxvFu47OqNWiy
l+ayhVtVa+gNE50qYvTvRK5NecFeoqyrWN4OYGJpfdV5UqIaoAHDQBDxjQ3li2yP5qcKEvR5IL04
sBqLqJlr+DzHXw/w6QwYxCiSwMmE/evBukB4gyVvFoK6GdjaUeC5ZfPMUKdi5QyL1r9ZxAhvJNAK
xvRoRfitgS7KuOCptTePNUgsBeFTYJwNQIKxhgQ931BevTDZ8nwrb76IhcjYa7+0YKyOVoQvVC1j
uFLy2K1jKNcsg+Tvg1CcYjEfgSjY7ZJPqkj2560L/gXMl2a63sPBwNtx4TpjoFGX7SOavrxZsWQb
gCutKk8K+rKT7H1QhLPTvwxVJq0J5RW0td3JiFdZMB44CswP58QI3rrAw3va4t/aUrnU9lz1+yAD
izgOxxkNAZNzZLwawLX9nbd1jiYjDSR8SbUGjXPx1gZuzG9r28RAWkG++4Vx1YgC13veSNVfkKGn
g27Z0ZiJelLrDlzjrQv0mwwx640GTAknyVIOc6EdWTk1hzK19uax3kjwLRDugb2pd8YAdKmvLrDC
V3/TdNXtVg7jsv5SJ2XNU8dAZMNmygPQhx2egskOmFAckBkPzHN3HTtyYpxNg+Mucol4HguDRJxl
3OXuBLpgxHNk3oQd76w7xZm5wkvqAn5i1Kqom6Q4rmH2onftKr/o+fn5I3a63gFDaSCrQfsA1NbQ
vmlk7yBvWW00RTXz80cUuLIpNKaMic4jk92Zngl89cHvsMQNALsBTAQw2MhLAngHoBgYv4xWVIdV
69+HNxK4D8A3oWD+EQBA6IyRa6Jtr6Jr5hre/ONeAZGjR08MXNfoDy8/0C3ZAoeEN9JWxYImEOPr
Tg5CPhiphMCwi5K6gF9IXM6Ey5HeZ0Ey0UNg+lOjf9GTqtrjjQR/AvCtcMCUgCQ6tam8+lVb2lkX
WAvGOarbODj0ZtRfvf/NnuONxrfy5s+DaC4T2RI6NNM4wWhKaqvOJaIHAUyyvHDCepbGjY0Vd6/N
ZJt8KxdcxEI6aZnDuvEezxlrZ4Ys35+UHUYDMOiaRn/1Q4ADnH8gimrm53sjga0sxHNDxWQAoCCe
N0Vl/d5I8A9EtAZ2mAwAME4iMteU1AV/lKk2hTgkWMjHM1Vfgsz4uL3tEVtK5sRX2quEIL+BvYMZ
xxnNlPobjvBGAqv3zr2MRRaMupIh5qILVNRbvLLqeG8k8D7AX8lEfcQc8kYCb4LZ9t/fn+rbXkKq
IWDthHExQiHL/8ZYJrcfUB10ZvGKYCGgasKsPxhUUh+4VDD+yE7SZXk7ZRmARZms0lsXDIC5WkFr
T/RGAi159bdO/pdNk6MlkeClYD4l9RKYAfF3It4CACzJBEESMbNkg0GGEABLFAAoAyVlaLneU9vb
oswFILLs7ejOXF41OpYV378GGVwF4EeOUOurCeVwfvu9IE46zkU2ksnYwd66wP1gfEt1mzs8nnyr
X+tPqb8h1y3zPgRwZJK37gGwp2Or54TN1yavyRupehag6ejJvHHYEYsgXLSxPLzKyrZn07KOqD9M
jhg5cH7b+jT2nmgGoCQS+CGYr3PC02d+W1sjLJ4XclHhyUA8YZNh4HdEYnm0fNFz6dQb9S8+BwB8
q4KnSRNXEPOgc4jMXA7AUqPJJqbW3lyi9BNYvCI4QhiyCcii1b0WkIkRTWn9zT4pxToASlOh9qE+
+qqnAiFrouuXRAJvUwLZSZno56Kt9fsNlfe12tGoKfW3jnNz7P+BacA9SFb/zr21gXeRJQHfmPkK
ZZPBk2sWjBQGtww3kwEAu7chlK6aP1pKsRHOMhkAKPPNaE9jPuUAU+qDRYc1GcJmKXlyY3n1jXaZ
DNATJyZavvgySHwT3H820uL6qvMsrZTon3a1x2rIIJcyo8ktkKtVd4AqvJ6JRemXMjAy5vqx6jYO
qI14rRXluCQPmgKZIa+hNk9x0+zFmzLVtujs8C/dOTyVQIcE6CcWc6ysi4kTCjjlBMjEhUrmaLy1
gR8AOE11B6iCTTEJ6SVtHxDvysDJIFyfqjQJvp0gOgXLGNj4W8PsRQ3okwJ0Sv0N49wydzYTRhKL
USD+ATixiSAC8r0rA9+Izg7/Mp12CkZ+fy/OCYhLNi5orAivtaN/D8f6Cxe3ATjaWxe4EYx79usi
pBA5cmBIspklgSXBxGUZN5riJ6pOgok7VTdeKWRTjGMOCa5r/TUlNfnLLQC9JgV+0FQWTmg43ly2
bCuAX/U69MPP/vV7o7rd7sfAKGXCpwZtvsCPAKRlNCxwcj8ZkD8wYJzdUHH325b1aYpEy8M/K4ks
eEWAf0LSXLDRv+QVK8tnwMwOmwEAGpVRo5nx6jx3+0e0TnWzhyrTVnaPMw36dKLXM+Fajyys2ZtH
PS1ev+SeXQDOm7QmlFfQ3jqbmQbMQcRAkW/lgtKG2Ys2pl4jTe0z0Hqjw+M5zUk74xv9i54HYO3c
zF4Mks0S2RNxJKNzNB0fjfwBhvJiPMWYInY1DvM7ZaADjK+43VzYWB7+jRUm05vNM0OdDeWLa+KI
n0DgewfUITiteSSGXNPrpwZp0iwnmYzdGMKVeO4zB5Cx0Zdv5YKJLKQt8xLZBjPubqwI32p1uQks
4noq6g9nNCLhSU9WfSoepzeYD36cYmBNoz+c1re9ry7wOwAw2Lj9Db/6x6VMk02L9jI2umAhM7bJ
zunsDT9qqdH4Vi6YyBhweUq8w+MZoeIbf31PAO/x3khVBKDy/X0AjASDQEj5j6WhPHxFptujSY1M
Pjp9WXVjHURaCdP7RZhfGODMVoCnq36siL5aOJuA/973M0POS8dkNAAIjoxw2B8ZGdH4aoPVDPao
bqyDsHwWTzLyD33byeuE4Ks2li1pUN1ghEKyAfg1ev6vsQKm1QCXp1+Q7WywfUQzt6bGYOKrVLfU
afhqQpaGNSBx8O5gBv97z2jzbEeYjMYWiKSSuMhJ6wQes91oGvLXTQOcG0hZFZzfauk3UYw8BzJ8
Mj7ozjXO2XJW5vINaTKPGccLqjUkgmRusv3RyWQ5hch5AbaUQ/gigL9aVVxzWagFAE1bcdv4DbPv
+kTPfwx93IaImVnwaybCbtuNRggscn5XZB4iGmVHuRvm3PWx6rZpNL2RpnjO9pEGAyeobqhGo1FH
05zqPbYaTckT35ukupFOhSXs2e+k0TgIadKpgN3raEzD8SkhlEFcqlqCJrsxmeMAzLQLsgvCjkJR
0ADYbTRMh41+NnyhsaoVaLIb2WM0lueNsgzGv/btpdNvgzSaLEUYLAFYEhbVDtxddM1+rarFDGdO
rKs6XbUGTfbC3W5CcsGHMigOD6+/rHrLvh+10SjEBeiNppqUMQRcYGeGXZGSl/T+WRuNShifUy1B
k73E8sgFR8Z34sub5ixe3/uINhqlOHPUq8kOcmJOjOZJH8dEV33fo/YaDZFjJ6ocQs7U2vl6PY1m
aEDYvac9dnxz2bKWvqdsNhpuVt12h5NnSKFXTmuGBkwLt1T2v5HXVqOJt7mfUt12p8MkHDj81WQD
Enymag37YOaFUX/1ooHO22o0zZULt6ruAKdDgs5XrUGTnTDzd1Rr6BGChxsrFt822CUZ2FSZPRn1
1CB/oFqBJkshmq5aAoBHohXhqw93ke1GI1jcrLonnE2WpBvUOIriFcERqjWAcFPUH74ykUttN5o4
zPcBdKvuEydTUhe4TLUGTXYhXLhYXe38IRhTo+XhpQnrtVvSmxVLGgHarq5TnA+B9FYETXKwnKSg
VgnwfVH/4mOiFeG3krkxIwv2BLF+fBoMlj7VEjRZBotghmv8SJrki/oXX5/KzRmbH/BGAh8COCpj
3ZJdmFF/2IFLyTVOxFe3YA6zfDxD1d3PAo83loVXp1NIxj7cRPR7Zr4pU/VlGcbU2uBpb1ZUv6xa
iMbhcEigvu37NlawA6CNILwQLQ8vsKrUjBlN/vhdt7R/NFIbzQAYxL8C8GnVOjTOZsoT2wuZc0+x
9GGE8GKc41cI0721bVw8bkeanoy+WvVFAjcw8PNM1plFfNyVK4rfvmDRbtVCNM6kqGZ+/ogC1zYA
BVaWy4RbG8vDd9upPbO7t9s9DwDQq4X7Z3xeB4pUi9A4l8J8992w2GQAgCQ22a09o0bTUBnqjrZv
PhpOjnOqECnkStUaNM6kJBJcRsTftaNsKeg/duvPfDyaykdNML6X8XqzAAIml66YP0W1Do2z8EaC
ywk8T7WOdFAS+CpaEf4FgEdVN96JmC73XNUaNA6hZq7hiwSXAfxNADmq5aSDsgh7UX+4Emz/s2G2
Qcx3QofeG94wU/HKquO9Bce/x3DIDu00URrKc3yhZyoYTao7wWEY3rrAMtUiNGo4pnZegbcuuFEY
1ATw0RmoMm6YMctfZ/fFEd+cvkjVKgZdqFqHU2Bgi+f93ZPXXbc8plqLJgMwU0kkcAsRTQfhv8CZ
/LvklphwT2kuszd2lCOMBgB8dVV3MVMAjozqrgK6J+qvnq9ahcZ6Jq0J5RW0t35GSuQTUQBAmUI5
H0XLw8eAwHZW4hijAUAzahYc0e6RW8FwqxbjBJh5dmPF4lrVOjTpU/r4zT5p0CMgmoKeKYt8OCML
yUdRf9j2RzQnGQ0AoHTV/NEybgQBsmyfRRazTcRd52y8eGGDaiGawSmpu3Wq4PgVAE4AgViigAin
MGFCZh+FkmZt1B+eaXclju0AX83/FLLH80tIzAU5wvnVwFgvmS9umr1Yv6FTQSgkiqe3TnMZYpJJ
7IFkEkR5DFwG4CwAo1RLTAeT6fRMbOZ1rNHsJxQSJae21hCLC0Hscfi3g110E+H0hvLw66qFDAlC
ITHpHOQc0d1qdHWK/V9iIpdPhImFYJwMYKxqmZkg6g9n5O8pa/5op9TfkJsj806ToMkEXo4sX8CU
NAzJ4IcaKxZ/XbWUbOCkh6s8sdGoYNBMAopw8B6hAmZMIsJIALmqtSrkg6g/nJEEhlljNP3he2rB
RO6K+wlict9zDAzNqH6MDhAvJMLqhvLFL6mWYxeTa+aNzCkYPYXYnAKBEQBGMFPfR+hiAs8gRhEL
HKlfIiTNI4kGF0+XrDaawfBGgm0AW77T1WFIBlYLGa9icnUTiy507tnRUHlfayYqn/TgNXmiaHxu
QbuRh7zOnLjMMVydpmF6ILifvNAcdxG5+Tgwn0kszgZ4IoCxAI/S2SAyDzP9vLGi+sZM1DVkf7m+
uqrvMtPPVOvIMF1gbAfRfqNhsCRgJwO7iagFErtJYI8peScJNoiNkYA5GoxcBhUQuABEHgAjATrM
mibOAyi351/kADAYMAgwVHeE5vAIV3zMxllLd2SiriG7OI4Zw/GVcC4Ix6DX2ivq/S8zQD3/CALA
BED2nKV91/b+7klkDdfB1wzZb66hxweZMhnAGQuGbEHmiAYQ9BJ+jaYfiCmjWRSGrNE0fan6AzBM
1To0GkfiRlJ5mdJlyBoNADDoW6o1aDROpGFWZjNuDGmjafRXP9QzI6HRaPYhhevzma5zSBtND/Si
agUajYN4t+nl3BcyXenQNxqid1VL0GicA0cQCslM1zrkjYaFuEW1Bo3GIWxLNXd2ugx5o2m86O7N
AN5RrUOjUQ0BykKvDHmj6YHvVK1Ao1FNu8fze1V1DwujIWk8pVqDRqMQk0mctHlmqFOVgGFhNA2z
F71LQFS1Do1GCUQrGssXvaFSwrAwGgAQZrft4Qo1GsdB2D2t7ZRK1TKGjdGMPeLI7QCUurpGk2lM
SV96tLJS+VacYWM0a2eG4mA8oFqHRpMZmMFYkIl4wIkw7Hb1eyOBHQCOVK1Do7GZd6L+8CTVIvYx
bEY0+yCIu1Rr0Ghs5m9OMhlgGBpNafvbSwHsUq1Do7EF5n+43TxHtYy+DLtHJwCYtqLqJNPA6zpO
rWaI8T53uz7TeOnC7aqF9GXYjWgAIPejlihAH6jWodFYyBvk8ZQ40WSAYWo0665bHhNm/FwkFhRX
o3E0BKzKjcW+0DAzlJHsF6kwLI0GADbOWdpMzEtV69Bo0uSlhlc95a9fcs8u1UIGY9gaDQDkH92i
Q0hoshYi/D76qucsFfFlktaqWoBqSuoD5xBjzTDN6a3JVlx8QXTW4qdVy0iUYT2iAYDGsvCzBPxB
tQ6NJhGY+Zk44idkk8kAekSzH2+k6p8AnaVah0YzADEQlkfLw99RLSQVhmymymRhw3WFkOabrBPF
a5wG42MAZ0f94YzmYrISPaLpRUlt1ZlE9LxqHRrNXjaB8bOov/rnIMrqpRjaaPrgrQ+eASmfA0iP
bDSqaGfGXxsrwl9TLcQqhv1kcF+iF1W/BIiM5iXWaA7A95mcM3UomQygRzQD4osEqhgIq9ahGQYQ
drPkH3Ou+GPTl6qH5NYYbTSD4K0LzAewCHqCWGM9khnryMCfo2XhatVi7EYbzeBQ8YrgVGFwo2oh
mqEE10lTXN70r4K2bFjVawXaaBLAV1d1OjM9ByBHtRZNlkL4BTGede3g+vVXLW5TLSfzzdckhG/l
goks5M8BOC6okMaRvMzMzQT6XbQiXK9ajGq00SSJty5wLxjfhB7daA7QDaCLgPfjLrr6zVnOCAju
JLTRpMCJkfmTXXA9AuBM1Vo0SpBMXAfQchD2uIAtG172bBou8y2poI0mDXx1wW8x838DOEW1Fo3F
EH0Ilq8B4i0Cm2BqNQUiTa8UvKYNJXm00ViAr/am85mM+wGcAL0I0onEALSC0QHidkD0MgreScCT
AJ5uaN/8PCofVZ5sbSiijcYqQiFROn33ZGm47wP4AtVyhiHbAH4GoLVEeEHCbN93gk0RM13u1gIj
1r7+hcIOPSLJPNpobKB4RXAECXk1EZ0H4BLVerIJBpsEepsIjQxEmemTfecEyRix2Czd8m3a3b65
ofI+x8bI1RyMNhq7YZA3EqgE4VsAjgDjs6Ah/3jVAWA3CLsZaCGJdlCvQPCMVgatM1x4LRaPrXuz
Yun7qgVr7EUbTSapmWv4xpXmmy3tZAhcwcSLAXhUy0oObgbEqwReZ4LWmcLYYMTMzt5X5OZJ2ZJT
aI4Z8YG5bsYxJkg/qgx3tNE4gElrQnl57R0nkjSnMJC//4QwjiLIE5kxCYRjSGIsE46k3tccBJsA
7QJ4F0DbGdhB4G0g+gDMHxKwTTIO+aMXAnHJvFsQ7ZJw74x1xXaN2tqyc911y2Oq+0YzNPg/RZ85
y4TngsIAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjQtMTEtMjhUMDU6MDg6MjkrMDA6MDCc0vAtAAAA
JXRFWHRkYXRlOm1vZGlmeQAyMDI0LTExLTI4VDA1OjA4OjI5KzAwOjAw7Y9IkQAAACh0RVh0ZGF0
ZTp0aW1lc3RhbXAAMjAyNC0xMS0yOFQwNTowODoyOSswMDowMLqaaU4AAAAASUVORK5CYII="
        />
      </svg>
    </SvgIcon>
  )
}

export function LinkedinLogo(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="800px"
        height="800px"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="20" fill="#0077B5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7747 14.2839C18.7747 15.529 17.8267 16.5366 16.3442 16.5366C14.9194 16.5366 13.9713 15.529 14.0007 14.2839C13.9713 12.9783 14.9193 12 16.3726 12C17.8267 12 18.7463 12.9783 18.7747 14.2839ZM14.1199 32.8191V18.3162H18.6271V32.8181H14.1199V32.8191Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2393 22.9446C22.2393 21.1357 22.1797 19.5935 22.1201 18.3182H26.0351L26.2432 20.305H26.3322C26.9254 19.3854 28.4079 17.9927 30.8101 17.9927C33.7752 17.9927 35.9995 19.9502 35.9995 24.219V32.821H31.4922V24.7838C31.4922 22.9144 30.8404 21.6399 29.2093 21.6399C27.9633 21.6399 27.2224 22.4999 26.9263 23.3297C26.8071 23.6268 26.7484 24.0412 26.7484 24.4574V32.821H22.2411V22.9446H22.2393Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  )
}

export function RedditLogo(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="800px"
        height="800px"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 2C8.27812 2 2 8.27812 2 16C2 23.7219 8.27812 30 16 30C23.7219 30 30 23.7219 30 16C30 8.27812 23.7219 2 16 2Z"
          fill="#FC471E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0193 8.90951C20.0066 8.98984 20 9.07226 20 9.15626C20 10.0043 20.6716 10.6918 21.5 10.6918C22.3284 10.6918 23 10.0043 23 9.15626C23 8.30819 22.3284 7.6207 21.5 7.6207C21.1309 7.6207 20.7929 7.7572 20.5315 7.98359L16.6362 7L15.2283 12.7651C13.3554 12.8913 11.671 13.4719 10.4003 14.3485C10.0395 13.9863 9.54524 13.7629 9 13.7629C7.89543 13.7629 7 14.6796 7 15.8103C7 16.5973 7.43366 17.2805 8.06967 17.6232C8.02372 17.8674 8 18.1166 8 18.3696C8 21.4792 11.5817 24 16 24C20.4183 24 24 21.4792 24 18.3696C24 18.1166 23.9763 17.8674 23.9303 17.6232C24.5663 17.2805 25 16.5973 25 15.8103C25 14.6796 24.1046 13.7629 23 13.7629C22.4548 13.7629 21.9605 13.9863 21.5997 14.3485C20.2153 13.3935 18.3399 12.7897 16.2647 12.7423L17.3638 8.24143L20.0193 8.90951ZM12.5 18.8815C13.3284 18.8815 14 18.194 14 17.3459C14 16.4978 13.3284 15.8103 12.5 15.8103C11.6716 15.8103 11 16.4978 11 17.3459C11 18.194 11.6716 18.8815 12.5 18.8815ZM19.5 18.8815C20.3284 18.8815 21 18.194 21 17.3459C21 16.4978 20.3284 15.8103 19.5 15.8103C18.6716 15.8103 18 16.4978 18 17.3459C18 18.194 18.6716 18.8815 19.5 18.8815ZM12.7773 20.503C12.5476 20.3462 12.2372 20.4097 12.084 20.6449C11.9308 20.8802 11.9929 21.198 12.2226 21.3548C13.3107 22.0973 14.6554 22.4686 16 22.4686C17.3446 22.4686 18.6893 22.0973 19.7773 21.3548C20.0071 21.198 20.0692 20.8802 19.916 20.6449C19.7628 20.4097 19.4524 20.3462 19.2226 20.503C18.3025 21.1309 17.1513 21.4449 16 21.4449C15.3173 21.4449 14.6345 21.3345 14 21.1137C13.5646 20.9621 13.1518 20.7585 12.7773 20.503Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  )
}

export function GlassdoorLogo(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="800px"
        height="800px"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="512" cy="512" r="512" style={{ fill: "#0caa41" }} />
        <path
          d="M621.7 694.9H329.1c0 40.4 32.7 73.1 73.1 73.1h219.4c40.4 0 73.1-32.7 73.1-73.1V394.5c0-1.5-1.2-2.7-2.7-2.7h-67.8c-1.5 0-2.7 1.2-2.7 2.7v300.4h.2zm0-438.9c40.4 0 73.1 32.7 73.1 73.1H402.3v300.4c0 1.5-1.2 2.7-2.7 2.7h-67.8c-1.5 0-2.7-1.2-2.7-2.7V329.1c0-40.4 32.7-73.1 73.1-73.1h219.5z"
          style={{ fill: "#fff" }}
        />
      </svg>
    </SvgIcon>
  )
}

export function MoreLogo(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="48px"
        viewBox="0 -960 960 960"
        width="48px"
        fill="#56ba73"
      >
        <path d="M453-280h60v-166h167v-60H513v-174h-60v174H280v60h173v166Zm27.27 200q-82.74 0-155.5-31.5Q252-143 197.5-197.5t-86-127.34Q80-397.68 80-480.5t31.5-155.66Q143-709 197.5-763t127.34-85.5Q397.68-880 480.5-880t155.66 31.5Q709-817 763-763t85.5 127Q880-563 880-480.27q0 82.74-31.5 155.5Q817-252 763-197.68q-54 54.31-127 86Q563-80 480.27-80Zm.23-60Q622-140 721-239.5t99-241Q820-622 721.19-721T480-820q-141 0-240.5 98.81T140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z" />
      </svg>
    </SvgIcon>
  )
}
