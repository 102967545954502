import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
   FormControlLabel,
   Checkbox,
   Link,
   Typography,
   Paper,
} from "@mui/material";
import { useSessionStorage } from "@uidotdev/usehooks";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../app/hooks.ts";
import { closeDialog, selectIsOpen } from "./authDialogSlice.ts";

function ToastSuccess() {
   return (
      <Paper sx={{ boxShadow: "none", border: "none" }}>
         <Typography>Successfully logged in</Typography>
         <Typography>You are available for searching people</Typography>
      </Paper>
   );
}

export default function AuthDialog() {
   const dispatch = useAppDispatch();
   const isOpen = useAppSelector(selectIsOpen);
   const [lc, setLinkedinCredentials] = useSessionStorage(
      "linkedinCredentials",
      {}
   );
   const handleClose = () => {
      dispatch(closeDialog());
   };

   function handleLinkedinCookie(cookie) {
      if (!cookie) return false;
      const cookie_val = { li_at_cookie: { li_at: cookie } };
      //{ li_at_cookie: encryptAndStorePassword(cookie) };
      setLinkedinCredentials(cookie_val);
      return true;
   }
   return (
      <>
         <Dialog
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
               component: "form",
               onSubmit: (event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries(formData.entries());
                  const li_at = formJson.li_at;

                  if (handleLinkedinCookie(li_at)) {
                     toast(ToastSuccess, { autoClose: 3000 });
                     handleClose();
                  } else {
                     toast.error("Cookie is invalid");
                  }
               },
            }}
         >
            <DialogTitle>LinkedIn Login Required</DialogTitle>
            <DialogContent>
               <TextField
                  sx={{
                     "& .MuiInputLabel-root": {
                        color: "#56BA73",
                        "&.Mui-focused": {
                           color: "#397c4d", // Ensure label color stays the same when focused
                        },
                     }, // Label color
                     "& .MuiInput-underline:before": {
                        borderBottomColor: "#56BA73",
                     }, // Underline color when not focused
                     "& .MuiInput-underline:after": {
                        borderBottomColor: "#56BA73",
                     }, // Underline color when focused
                     "& .MuiInputBase-input": { color: "#56BA73" }, // Text color

                     "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                           borderColor: "#5fcf80",
                        },
                        "&:hover fieldset": {
                           borderColor: "#5fcf80",
                        },
                        "&.Mui-focused fieldset": {
                           borderColor: "#5fcf80",
                        },
                     },
                  }}
                  autoFocus
                  required
                  margin="dense"
                  id="li_at"
                  name="li_at"
                  label="LinkedIn 'li_at' Cookie"
                  fullWidth
                  variant="standard"
               />
               <FormControlLabel
                  required
                  control={<Checkbox />}
                  label="I have read and agree to the"
                  sx={{ mr: 0 }}
               />
               {/* todo add link to terms of service */}
               <Link
                  href="public/docs/CareerGPT Inc. - Terms of Use.docx"
                  target="_blank"
                  sx={{
                     textDecoration: "none",
                     display: "inline-block",
                     transform: "translateY(2px)",
                     fontWeight: "600",
                     color: "#5fcf80",
                  }}
               >
                  Terms of Service
               </Link>
            </DialogContent>
            <DialogActions>
               <Button sx={{ color: "#56BA73" }} onClick={handleClose}>
                  Cancel
               </Button>
               <Button sx={{ color: "#56BA73" }} type="submit">
                  Submit
               </Button>
            </DialogActions>
         </Dialog>
      </>
   );
}
