import React, { useEffect, useState } from "react";
import { FaRegStopCircle } from "react-icons/fa";
import { GrUploadOption } from "react-icons/gr";
import { LuSendHorizontal } from "react-icons/lu";
import { useCreateChat } from "../../hooks/useCreateChat";
import { useCreateConversation } from "../../hooks/useCreateConversation";
import { useUpdateChat } from "../../hooks/useUpdateChat";
import { useUserConversation } from "../../hooks/useUserConversation";
import { useGetMe } from "../../hooks/useGetMe";
import { useQueryClient } from "@tanstack/react-query";
import { uploadFileToS3 } from "../../services/uploadToS3";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import getSocketInit from "../../socket";

// const CONVERSATIONCOUNT = 20;

const TypeBox = ({
  userChatData,
  isRating,
  id,
  setCvUploading,
  message,
  setMessage,
  setConversation,
  setIsRating,
}) => {
  const socket = getSocketInit();
  socket.connect();
  const [isTyping, setIsTyping] = useState(false);
  let typingTimeout;
  const queryClient = useQueryClient();
  const { uploadData, isPending: uploadingData } = useCreateChat();
  const { uploadMessage, isPending: uploadingMessage } =
    useCreateConversation(id);
  const navigate = useNavigate();
  const { updateChat, isPending: isUpdateChat } = useUpdateChat({
    id,
    userChatDataId: userChatData?.id,
  });

  const { myData, isPending } = useGetMe();

  const { recruiterData, isPending: loadingRecruiterData } =
    useUserConversation(id);

  const [chatId, setChatId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleFileChange = async (event) => {
    setCvUploading(true);

    const file = event.target.files[0];

    if (file) {
      const fileType = file.name.split(".").pop();

      setSelectedFile(file);

      const newFile = new File([file], `${file.name}`, {
        type: file.type,
        lastModified: file.lastModified,
      });

      try {
        const url = await uploadFileToS3(newFile, "chat", newFile.name);
        handleSendMessage({ conversation: url });
        setCvUploading(false);

        // Here you can handle the generated URL further, e.g., displaying it or storing it in state
      } catch (error) {
        toast.error("Failed to upload file.");
      }
    }
  };

  const handleSendMessage = async ({ conversation = message } = {}) => {
    setIsTyping(false);
    clearTimeout(typingTimeout);
    socket.emit("stop typing");

    const userChatId = recruiterData.conversationData.userChatExist
      ? recruiterData.conversationData.userChatExist
      : chatId;

    if (!userChatId) {
      // if (myData.subscriptionPlan == "free" && myData?.chatCount > 0) {
      //   setShowModal(true);
      //   return;
      // }

      const chatData = {
        receiverId: recruiterData.recruiterData.id,
        receiverName: recruiterData.recruiterData.name,
        receiverImg: recruiterData.recruiterData.image,
        receiverUserType: recruiterData.recruiterData.userType,
        senderId: myData.id,
        senderName: myData.name,
        senderImg: myData.image,
        senderUserType: myData.userType,
      };
      return uploadData(
        { chatData },
        {
          onSuccess: (data) => {
            {
              setChatId(data.details);

              queryClient.invalidateQueries({
                queryKey: ["userChat", id],
              });

              const conversationData = {
                fromId: myData.id,
                toId: recruiterData.recruiterData.id,
                userChatId: data.details,
                text: message,
                lastUserName: myData.name,
              };

              uploadMessage({ conversationData });

              const conversationDataTempary = {
                fromId: myData.id,
                toId: recruiterData.recruiterData.id,
                userChatId: data.details,
                text: message,
                createdAt: new Date(),
              };
              setConversation((conversation) => [
                ...conversation,
                conversationDataTempary,
              ]);
              setMessage("");
            }
          },
        }
      );
    } else {
      const conversationData = {
        fromId: myData.id,
        toId: recruiterData.recruiterData.id,
        userChatId: userChatId,
        text: conversation,
        lastUserName: myData.name,
      };
      uploadMessage({ conversationData });
      const conversationDataTempary = {
        fromId: `${myData.id}`,
        toId: `${recruiterData.recruiterData.id}`,
        userChatId: userChatId,
        text: conversation,
        createdAt: new Date(),
      };
      setConversation((conversation) => [
        ...conversation,
        conversationDataTempary,
      ]);
      setMessage("");
    }
  };

  function handleInput(value) {
    setMessage(value);
  }

  const handleChatCloseBtn = () => {
    const userChatId = recruiterData.conversationData.userChatExist
      ? recruiterData.conversationData.userChatExist
      : chatId;

    if (!userChatId) return toast.error("Chat hasn't started yet");

    if (userChatData?.conversationCount < 3) {
      return toast.error(
        "You need to have more conversations with this user before you can submit a manual rating."
      );
    }

    if (userChatData?.isManuallyRate) {
      return toast.error(
        "You have already submitted a manual rating for this user."
      );
    }

    const conversationData = {
      toId: recruiterData.recruiterData.id,
      userChatId: userChatId,
      text: "You can give your feedback here",
      lastUserName: "admin",
    };

    uploadMessage({ conversationData });

    const conversationDataTempary = {
      userChatId: userChatId,
      text: "You can give your feedback here.",
      lastUserName: "admin",
    };
    setConversation((conversation) => [
      ...conversation,
      conversationDataTempary,
    ]);
    setIsRating(true);
    // updateChat(userChatData.id);
  };

  function closeBtnAndChatLimitFunction() {}

  function handleClosebtn() {
    setShowModal(false);
  }

  function handleSuccess() {
    navigate(`/${myData.userType}/subscription-plan`);
    setShowModal(false);
  }

  return (
    <div
      className="input-group align-self-end ps-3 w-100 pb-5"
      style={{
        height: "10vh",
      }}
    >
      <input
        className="px-3 py-3 w-100 border-0 rounded-5"
        disabled={isRating}
        style={{
          backgroundColor: "#F8F8F8",
          outline: "none",
          fontSize: "14px",
        }}
        placeholder={message == "" ? "Type your message" : ""}
        onChange={(e) => handleInput(e.target.value)}
        value={message}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault(); // Prevents the default behavior (form submission)
            handleSendMessage(); // Call the function to handle submission
          }
        }}
      />
      <div className="input-group-append">
        <span
          className="d-flex gap-4 align-items-center"
          style={{ position: "absolute", right: "10px", top: "12px" }}
        >
          <input
            type="file"
            style={{ display: "none" }}
            id="fileInput"
            onChange={handleFileChange}
          />
          <button
            onClick={() => document.getElementById("fileInput").click()}
            style={{
              padding: "0px",
              border: "none",
              backgroundColor: "transparent",
            }}
          >
            <GrUploadOption size={23} />
          </button>
          <button
            onClick={handleSendMessage}
            style={{
              padding: "0px",
              border: "none",
              backgroundColor: "transparent",
            }}
          >
            <LuSendHorizontal size={23} />
          </button>
          <button
            onClick={handleChatCloseBtn}
            style={{
              padding: "0px",
              border: "none",
              backgroundColor: "transparent",
            }}
          >
            <FaRegStopCircle size={23} />
          </button>
        </span>
      </div>

      {/* 
      <div>
        {showModal && (
          <SubscriptionModal
            show={showModal}
            handleClosebtn={handleClosebtn}
            handleSuccess={handleSuccess}
            title="Free Plan Limit Reached!"
          >
            <p>
              You've reached the chat limit for our Free Plan. Currently, you
              can chat with only one user. To unlock unlimited chats and enjoy
              additional features, consider upgrading to our Pro Plan. Click the
              button below to explore our subscription options and continue
              connecting with more users seamlessly.
            </p>
          </SubscriptionModal>
        )}
      </div>
      */}
    </div>
  );
};

export default TypeBox;
