import io from 'socket.io-client';
import { serverHost } from "./services/api.js";

export default function getSocketInit() {
    if (window.socket) {
        return window.socket;
    }
    const socket = io(serverHost, {
        autoConnect: true,
        auth: {
            "userId": localStorage.getItem("userId"),
            "user": JSON.parse(localStorage.getItem("userObject")),
        },
        transports: ["websocket", "polling"],
        withCredentials: true
    });
    window.socket = socket;
    return socket;
}
