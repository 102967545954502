export const serverHost = "https://app.careergpt.io";

// export const serverHost = "http://localhost:8000";
// export const serverHost = "http://127.0.0.1:8000";

export const baseUrl = serverHost + "/api/v1";

// export const FAST_API_PYTHON_SERVER_URI = 'http://localhost:8000';
export const FAST_API_PYTHON_SERVER_URI = serverHost;

export const COOKIE_HASH_KEY = "CareerGPT";
