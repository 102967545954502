import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useGetMe } from "../hooks/useGetMe";

export default function PublicRoutes() {
   console.log("PublicRoutes");
   const { myData, isPending } = useGetMe();
   if (isPending) {
      return <div>Loading...</div>;
   }
   const authToken = sessionStorage.getItem("authToken");
   //to do: add a check for the user type and redirect to the appropriate layout
   return myData || authToken ? (
      <Navigate to={`/${myData?.userType || "candidate"}layout`} />
   ) : (
      <Outlet />
   );
}
