import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Logo from "./Logo.tsx"
import { Container } from "@mui/material"
import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import { NavLink } from "react-router-dom"

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
}

const drawerWidth = 240
const navItems = ["Home", "Contact", "Pricing", "Blog"]

export default function DrawerAppBar(props: Props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const location = useLocation()
  const pageNow = location.pathname === "/" ? "/home" : location.pathname
  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState)
  }

  const drawer = useMemo(
    () => (
      <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
        <Container>
          <Logo />
          <Typography variant="h6" sx={{ my: 2 }}>
            CareerGPT
          </Typography>
        </Container>

        <Divider />
        <List>
          {/* {navItems.map(item => (
            <ListItem key={item}>
              <ListItemButton sx={{ textAlign: "center", padding: "10px " }}>
                <ListItemText
                  primary={
                    // <NavLink
                    //   to={`/${item.toLowerCase() === "home" ? "" : item.toLowerCase()}`}
                    //   style={{ textDecoration: "none" }}
                    // >
                    //   <Typography
                    //     sx={{
                    //       color:
                    //         pageNow === `/${item.toLowerCase()}`
                    //           ? "#5fcf80"
                    //           : "#777",
                    //       "&:hover": {
                    //         color: "#5fcf80",
                    //       },
                    //       fontWeight: "600",
                    //     }}
                    //   >
                    //     {item}
                    //   </Typography>
                    // </NavLink>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))} */}
        </List>
      </Box>
    ),
    [pageNow],
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{ backgroundColor: "#fff", padding: "10px 20px" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon sx={{ color: "#5fcf80" }} />
          </IconButton>
          <NavLink to="/" style={{ textDecoration: "none" }}>
            <Logo
              sx={{
                width: { xs: "60px", sm: "90px" },
                height: { xs: "60px", sm: "90px" },
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={() => {}}
            />
          </NavLink>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block", color: "#333" },
              marginLeft: "10px",
            }}
          >
            CareerGPT
          </Typography>
          {/* <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            {navItems.map(item => (
              <NavLink
                key={item}
                to={`/${item.toLowerCase() === "home" ? "" : item.toLowerCase()}`}
                style={{
                  textDecoration: "none",

                  marginRight: "20px",
                }}
              >
                <Typography
                  sx={{
                    display: "inline",
                    color:
                      pageNow === `/${item.toLowerCase()}` ? "#5fcf80" : "#777",
                    "&:hover": {
                      color: "#5fcf80",
                    },
                    fontWeight: "600",
                  }}
                >
                  {item}
                </Typography>
              </NavLink>
            ))}
          </Box> */}
          <Button
            variant="contained"
            size="large"
            sx={{
              marginLeft: "auto",
              backgroundColor: "#5fcf80",
              "&:hover": {
                backgroundColor: "#56ba73",
              },
              borderRadius: "20px",
            }}
            component="a"
            href="/signin"
            target="_blank"
            rel="noopener noreferrer"
          >
            Join CareerGPT
          </Button>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  )
}
