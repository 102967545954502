import { Box, Typography } from "@mui/material"
import TravelExploreIcon from "@mui/icons-material/TravelExplore"
import PersonSearchIcon from "@mui/icons-material/PersonSearch"
import AnalyticsIcon from "@mui/icons-material/Analytics"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined"
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined"
import React from "react"
interface Props {
  icon: string
  title?: string
  text?: string
}

const iconMap: { [key: string]: JSX.Element } = {
  time: <AddCircleOutlineIcon sx={{ color: "green" }} />,
  money: <MonetizationOnOutlinedIcon />,
  growth: <TrendingUpOutlinedIcon />,
  explore: <TravelExploreIcon />,
  search: <PersonSearchIcon />,
  analytics: <AnalyticsIcon />,
}

export default function CustomizedCard({ icon, title, text }: Props) {
  const IconComponent = iconMap[icon]
  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        gap: "1rem",
        width: "12rem",
        padding: "2rem",
        boxShadow:
          "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
        transform: "translateY(20px)",
        transition: "all 0.5s",
        "&:hover": {
          transform: "translateY(0px)",
        },
      }}
    >
      {React.cloneElement(IconComponent, {
        sx: { color: "#5fcf80", fontSize: "3rem" },
      })}
      {title && <Typography variant="h4">{title}</Typography>}
      <Typography variant="h6">{text}</Typography>
    </Box>
  )
}
