import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { CircularProgress, DialogActions, DialogContent } from "@mui/material";
//import type { ContactInfo } from "../types/contact_info"

// export interface SimpleDialogProps {
//   open: boolean
//   contactInfo: ContactInfo | {}
//   onClose: () => void
//   isLoading: boolean
// }

export default function ContactInfoDialog({
   open,
   contactInfo,
   onClose,
   isLoading,
}) //: SimpleDialogProps
{
   return (
      <Dialog open={open}>
         <DialogTitle>Contact Information</DialogTitle>
         <DialogContent>
            {isLoading ? (
               <CircularProgress color="success" />
            ) : Object.keys(contactInfo).length > 0 ? (
               <List>
                  <ListItem>{contactInfo?.email_address}</ListItem>
               </List>
            ) : (
               <Typography variant="body1">
                  No contact information found
               </Typography>
            )}
         </DialogContent>
         <DialogActions>
            <Button onClick={onClose}>Close</Button>
         </DialogActions>
      </Dialog>
   );
}
