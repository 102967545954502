import React, { useState, useEffect } from 'react';
import "../../css/CareerCoach.css";
import { 
    FaRobot, 
    FaGraduationCap, 
    FaChartLine, 
    FaComments, 
    FaLightbulb,
    FaBriefcase,
    FaQuestionCircle,
    FaTimes,
    FaArrowLeft,
    FaKeyboard,
    FaMicrophone,
    FaRegLightbulb
} from 'react-icons/fa';

const CareerCoach = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [showSidebar, setShowSidebar] = useState(false);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [showTips, setShowTips] = useState(true);

    const CHAT_BOT_URL = "https://chat.unith.ai/Northeastern-511/violet-9233?api_key=0799de2de6b04100998d76412028c6e1";

    // Close sidebar when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            const sidebar = document.querySelector('.help-sidebar');
            const helpButton = document.querySelector('.help-button');
            
            if (sidebar && !sidebar.contains(event.target) && 
                helpButton && !helpButton.contains(event.target)) {
                setShowSidebar(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Auto-hide tips after 10 seconds
    useEffect(() => {
        if (showTips) {
            const timer = setTimeout(() => setShowTips(false), 10000);
            return () => clearTimeout(timer);
        }
    }, [showTips]);

    const handleIframeLoad = () => {
        setIsLoading(false);
        setIframeLoaded(true);
    };

    const handleIframeError = () => {
        setIsLoading(false);
        setIframeLoaded(false);
    };

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    return (
        <div className="ai-coach-container">
            <div className={`chat-main ${showSidebar ? 'sidebar-open' : ''}`}>
                <div className="chat-header">
                    <div className="header-left">
                        <div className="logo-container">
                            <FaRobot className="robot-icon pulse" />
                        </div>
                        <div className="header-text">
                            <h1>AI Career Coach</h1>
                            <p className="header-subtitle">Your Personal Career Growth Assistant</p>
                        </div>
                    </div>
                    <div className="header-right">
                        <div className="ai-status">
                            <span className="status-dot pulse"></span>
                            AI Assistant Active
                        </div>
                        <button 
                            className={`help-button ${showSidebar ? 'active' : ''}`}
                            onClick={toggleSidebar}
                            aria-label="Toggle help"
                        >
                            {showSidebar ? <FaTimes /> : <FaQuestionCircle />}
                        </button>
                    </div>
                </div>

                {showTips && (
                    <div className="tips-banner">
                        <FaRegLightbulb className="tips-icon" />
                        <p>Pro Tip: You can use voice input or type your questions</p>
                        <div className="input-methods">
                            <span><FaKeyboard /> Type</span>
                            <span><FaMicrophone /> Speak</span>
                        </div>
                        <button className="close-tips" onClick={() => setShowTips(false)}>
                            <FaTimes />
                        </button>
                    </div>
                )}

                <div className="chat-content">
                    <div className="chat-window">
                        {isLoading && (
                            <div className="chat-loading">
                                <div className="loader"></div>
                                <p>Initializing AI Career Coach...</p>
                            </div>
                        )}
                        <iframe
                            src={CHAT_BOT_URL}
                            className={`chat-iframe ${iframeLoaded ? 'loaded' : ''}`}
                            onLoad={handleIframeLoad}
                            onError={handleIframeError}
                            title="AI Career Coach"
                            allow="microphone"
                            frameBorder="0"
                        />
                    </div>
                </div>
            </div>

            <div className={`help-sidebar ${showSidebar ? 'show' : ''}`}>
                <div className="sidebar-header">
                    <button className="close-sidebar" onClick={toggleSidebar}>
                        <FaArrowLeft /> Close Guide
                    </button>
                </div>
                <div className="sidebar-content">
                    <h3>How to Use AI Coach</h3>
                    <div className="feature-list">
                        <div className="feature-item">
                            <FaGraduationCap className="feature-icon" />
                            <div className="feature-text">
                                <h4>Career Guidance</h4>
                                <p>Ask questions about career paths and opportunities</p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <FaChartLine className="feature-icon" />
                            <div className="feature-text">
                                <h4>Industry Insights</h4>
                                <p>Get real-time industry trends and analysis</p>
                            </div>
                        </div>
                        <div className="feature-item">
                            <FaLightbulb className="feature-icon" />
                            <div className="feature-text">
                                <h4>Skill Development</h4>
                                <p>Learn about key skills for your target role</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CareerCoach;