import {
    Grid2,
    Divider,
    Typography,
    Button,
    Stack,
} from "@mui/material"

export default function HeroSection() {
    return (
        <Grid2
            container
            sx={{
                position: "relative",
                minHeight: "5vh",
                py: 3,
                zIndex: 2,
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    color: "#effaf2",
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                    marginX: "8rem",
                    marginTop: '8rem',
                }}
            >
                Experience CareerGPT's{" "}
                <span style={{ color: "#5fcf80" }}>Agentic_AI</span>
                <br />
                to Automate Your Professional Workflows
            </Typography>
            <Stack spacing={2} width="60%" alignItems="center">
                <Divider sx={{ my: 5 }} />
                <Button
                    variant="contained"
                    href="/signup"
                    sx={{
                        borderRadius: "20px",
                        marginBottom: "10px",
                        padding: "6px 12px",
                        width: "150px",
                        fontSize: "0.875rem",
                        backgroundColor: "#5fcf80",
                        "&:hover": {
                            backgroundColor: "#56ba73",
                        },
                    }}
                >
                    Sign Up
                </Button>
                <Button
                    variant="outlined"
                    href="/signin"
                    sx={{
                        borderRadius: "20px",
                        padding: "6px 12px",
                        fontSize: "0.875rem",
                        width: "150px",
                        color: "#5fcf80",
                        borderColor: "#5fcf80",
                        "&:hover": {
                            backgroundColor: "#e8f5e9",
                            borderColor: "#56ba73",
                        },
                    }}
                >
                    Sign In
                </Button>
            </Stack>
        </Grid2>
    )
}
